import React, { PropsWithChildren, useEffect } from "react";
import { useStore } from "../../store/projectStore";

type Props = {};


export const Canvas: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const setViewer = useStore((state: { setViewer: any; }) => state.setViewer);
  // const element = React.useRef<HTML| null>(null);
  // const setRef = (node: HTMLElement | null) => {
  //   if (!node || element.current) {
  //     return;
  //   }
  //   element.current = node;
  // };

  useEffect(() => {
    console.log("setting up scene");
    // if (element.current) {
      setViewer("canvas");
    // }
  }, [setViewer]);

  return (
    <div>
      <div id="canvas"/>
      {/*{children}*/}
    </div>
  );
};
