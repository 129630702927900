// @flow
import * as React from "react";
import MolHeader from "../molecules/MolHeader";
import SvgButton from "../atoms/SvgButton";
import { tempNameSwitch } from "./Files.content";
import DownloadIcon from "../assets/DownloadIcon";
import { useStore } from "../../store/projectStore";
import { FilesListAllQuery, FileTypes } from "../../generated/clientTypes";
import Root from "./BuildTree";

type Props = {
  data: FilesListAllQuery | undefined
};

function Files({ data}: Props) {
  const visual = useStore(store => store.visual);
  const currentModel = useStore(store => store.currentModel);
  const [toggleVisual, getSceneModels] = useStore(s => [s.toggleVisual, s.getSceneModels]);
  // const [zipFile] = useZipFileMutation();
  const handleLocalDownload = (type: FileTypes, name: string) => {
  };

  return (
    <>
      {/*<ModelPopUp refetch={()=>refetch()}/>*/}
      <div className="bg-menuBackground w-60 m-2 px-1">
        <MolHeader text={"INVENTORY"} />
        <div className="p-1 px-3 flex flex-col w-full">
          <div className="flex flex-col text-left text-lg">
            <p className="text-lg">Analysis Layers</p>
            {data ? <div className="pl-4">
                <div className={data.filesListAll.includes("visual") ? "text-highPriority" : "text-gray-500"}>
                  <div className="flex flex-row w-full items-center focus:outline-none">
                    <button onClick={() => toggleVisual()}
                            className={`${visual ? "bg-highlight" : null} border-2 border-highPriority h-2.5 w-2.5 rounded-full`} />
                    <p onClick={() => toggleVisual()}
                       className="text-base align-top px-2">visual</p>
                    <SvgButton css="w-3.5 h-3.5" SvgSource={DownloadIcon}
                               onClick={() => handleLocalDownload("visual", "visual.zip")} />
                  </div>
                </div>
                {tempNameSwitch.map(({ vizName, name, downloadName }) => {
                  const stored = data.filesListAll.includes(name);
                  const color = stored ? "text-highPriority" : "text-gray-500";
                  const onDownload = stored ? handleLocalDownload : () => null;
                  const getModel = () => currentModel !== name ? getSceneModels(name) : null;
                  return (
                    <div key={vizName} className={color}>
                      <div className="flex flex-row w-full items-center focus:outline-none">
                        <button onClick={getModel}
                                className={`${currentModel === name ? "bg-highlight" : null} border-2 border-highPriority h-2.5 w-2.5 rounded-full`} />
                        <p onClick={getModel}
                           className="text-base align-top px-2">{name}</p>
                        <SvgButton css="w-3.5 h-3.5" SvgSource={DownloadIcon}
                                   onClick={() => onDownload(name, downloadName)} />
                      </div>
                    </div>
                  );
                })}</div>
              :
              <div className="flex justify-center h-35 w-35 items-center">
                <div className="animate-spin rounded-full w-32 h-32 border-b-2 border-highPriority" />
              </div>
            }
            <p className="text-lg">Layer Objects</p>
            <Root nodeId={-2} level={0} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Files;
