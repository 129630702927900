// @flow
import * as React from "react";
import MolHeader from "../molecules/MolHeader";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "../assets/UploadIcon";
import { useStore } from "../../store/projectStore";

type Props = {
  onUpload: () => void
};


export function Upload({ onUpload }: Props) {
  const [expanded, setExpanded] = useState(true);
  const [uploadHover, setUploadHover] = useState(false);
  const onConvert = useStore(store => store.onConvert);
  const convert = useStore(store => store.convert);
  const setView = useStore(store => store.setView);

  const callback = () => {
    onUpload();
    setView("Front");
  };
  // clean up later
  const onDrop = useCallback(
    async (acceptedFiles) => {
      await onConvert(acceptedFiles[0], callback);
    },
    [onConvert, callback]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // useEffect(() => {
  //     if(convert.status ==="success"){
  //         refetch();
  //     }
  //     return () => {
  //
  //     };
  // }, [convert.status]);


  const content = "Cambia supports:\r\nStep (.stp), Rhino (.3dm), & Revit (.rvt)";

  return (
    <div className="bg-menuBackground w-60 m-2 px-1">

      <MolHeader text={"UPLOAD"} active={expanded} onClick={() => setExpanded(!expanded)} />
      <div data-value={"child"}>
        <div  {...getRootProps()} onMouseEnter={() => setUploadHover(true)} onMouseLeave={() => setUploadHover(false)}>
          <div className="p-1 px-3">
            <a className="w-full text-lg text-left">{content}</a>
            <p className="text-highlight">{convert?.status ? convert.status : " "}</p>
            <div className="p-4">
              {convert?.status !== "loading" ?
                <UploadIcon hover={uploadHover} />
                :
                <div className="flex justify-center h-35 w-35 items-center">
                  <div className="animate-spin rounded-full w-32 h-32 border-b-2 border-highPriority" />
                </div>}
            </div>
            <p className="h-35 text-base text-center">Click or drop a file</p>

          </div>
          <input {...getInputProps()} className="opacity-0" />

        </div>
        <button className="p-1"><a href={process.env.PUBLIC_URL + "/revitInfo_v1.pdf"} download="Revit To Cambia">Revit
          info download</a></button>
      </div>
    </div>
  );
}

;
