import React, { useState } from 'react';

type Props = {
  onClick?: Function;
  active?: boolean;
  SvgSource: React.FC<{ hover: boolean }>;
  css?: string;
};

function SvgButton({ onClick = () => {}, active = false, SvgSource, css=''}: Props) {
  const [hover, setHover] = useState(false);
  return (
    <div onMouseEnter={() => setHover(true)} onMouseOut={() => setHover(false)} className="bg-opacity-0 bg-menuBackground">
      <button className={`focus:outline-none ${css}`} type="button" onClick={() => onClick()}>
        <SvgSource hover={hover || active} />
      </button>
    </div>
  );
}

export default SvgButton;
