// @flow
import * as React from 'react';

type Props = {
    content: string;
    fill?: string; // TODO type to branch colors?
    active?:boolean;
    onDotClick?: ()=>void;
    onClick?: ()=>void;
    hover?: string;
    secondary?: boolean;
};

export function DotToggleElem({fill="bg-highlight", active, onClick, onDotClick, hover, content, secondary} : Props) {
    return (
        <button className="flex flex-row w-full items-center focus:outline-none">
            <div onClick={onDotClick} className={`${active ? fill: null} ${hover} border-2 border-highPriority h-2.5 w-2.5 rounded-full`}/>
            <p onClick={onClick} className={`text-base align-top pl-2 ${secondary ? 'text-highlight' : 'text-highPriority'}`}>{content}</p>
        </button>
    );
};