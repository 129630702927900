// @flow
import * as React from 'react';
import LoginInput from "../atoms/LoginInput";
import {useFormFields} from "../../helpers/hooks";
import {useState} from "react";
import { useCreateProjectMutation } from "../../generated/clientTypes";
import { useStore } from "../../store/projectStore";
import { useNavigate } from "react-router-dom";

type Props = {
    user: string
    showPopup: () => void;
};

export type Fields = {
    project: string
}

export function CreateProject({user, showPopup}: Props) {
    const [fields, handleFieldChange] = useFormFields<Fields>({
        project: "",
    });
    const [invalid, setInvalid] = useState("");
    const [openOnCreate, setOpenOnCreate] = useState(true)
    const [createProject] = useCreateProjectMutation({variables:{user:user, project:fields.project}});
    const setProject= useStore((store)=>store.setProject);
    const history = useNavigate();

    const validateProject = () => {
        if (fields.project.length < 1) {
            setInvalid("project names need to have a length")
            return false
        } else if (fields.project.includes("/")) {
            setInvalid("project names can contain /")
            return false
        } else {
            return true
        }
    }

    const handleClick = async () => {
        if (validateProject()) {
            // handleCreateProject(fields)
            await createProject();
            if(openOnCreate){
                setProject(fields.project);
                history("/client/modeler");
            }
            else{
                showPopup()
            }
        }
    }
    return (
        <div className="h-screen w-screen">
            <div
                className="font-bold z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-auto bg-menuBackground text-highPriority">
                <div className="p-8 w-full flex flex-col flex-auto">
                    <div className="font-bold grid grid-cols-2 divide-x divide-highPriority">
                        <div className="flex flex-col pl-6">
                            PROJECT NAME:
                        </div>
                        <div className="flex flex-col pl-6">
                            <LoginInput labelName={"project"}
                                        placeHolder={"project"}
                                        value={fields.project}
                                        handleInputChange={handleFieldChange}
                                        handleEnterPressed={() => handleClick()}
                                        className={"font-bold bg-menuBackground text-highPriority px-2"}
                            />
                        </div>
                    </div>
                    <div className="flex-grow"></div>
                    <div className="pt-2 text-center">
                        <p className="text-highlight text-icon">{invalid}</p>
                    </div>
                </div>
                <div className="flex flex-col items-end">
                    <div className="p-3" onClick={()=>showPopup()}>
                        <p className="text-highPriority text-icon font-bold hover:text-highlight">X</p>
                    </div>
                    <div className="flex flex-row p-5 items-center">
                        <div onClick={()=>setOpenOnCreate(!openOnCreate)} className={`items-center mr-3 w-4 h-4 border-2 border-highPriority ${openOnCreate ? "bg-highlight": "bg-menuBackground"}`}/>
                        <p className="content-center">open</p>
                    </div>
                    <div className="p-3">
                        <button
                            className="focus:outline-none border-2 border-highPriority text-icon font-bold py-1 px-8 hover:text-highlight hover:border-highlight text-highPriority"
                            onClick={()=>handleClick()}>
                            CREATE
                        </button>
                    </div>
                </div>
            </div>
            <div onClick={()=>showPopup()}
                 className="absolute top-0 left-0 z-0 h-screen w-screen bg-menuBackground opacity-50"/>
        </div>
    );
};
