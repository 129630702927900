import * as React from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

export function useFormFields<T>(initialState: T): [T, (event: ChangeEvent<HTMLInputElement>) => void] {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event: ChangeEvent<HTMLInputElement>) {
      return event.target
        ? setValues({
            ...fields,
            [event.target.id]: event.target.value,
          })
        : null;
    },
  ];
}

export function useDragComponent() {
  const [pressed, setPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const ref = useRef(null);

  // Monitor changes to position state and update DOM
  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }
  }, [position]);

  // Update the current position if mouse is down
  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (pressed) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY,
      });
    }
  };
  return { ref, setPressed, onMouseMove };
}
