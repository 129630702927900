import React from 'react';

type Props = {
    hover: boolean;
};

function StatsIcon({hover}: Props) {
    return (
        <svg version="1.1" id="Capa_1" fill="none" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 391.925 391.925">
            <path
                d="M196.7,84.8a14,14,0,0,0-14,14v24.52a14,14,0,0,0,28,0V98.8A14,14,0,0,0,196.7,84.8Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
            <path
                d="M196.7,155.66a14,14,0,0,0-14,14v141.9a14,14,0,0,0,28,0V169.66A14,14,0,0,0,196.7,155.66Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
            <path
                d="M196.7,0C88.24,0,0,88.24,0,196.7S88.24,393.4,196.7,393.4s196.7-88.24,196.7-196.7S305.16,0,196.7,0Zm0,355.4C109.19,355.4,38,284.21,38,196.7S109.19,38,196.7,38s158.7,71.19,158.7,158.7S284.21,355.4,196.7,355.4Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
        </svg>
    );
}

export default StatsIcon;
