// import {Communicator} = Communicator.WebViewer;
/// <reference path="./hoops_web_viewer.d.ts"/>

import { Model } from '../store/projectStore';

type Props = {
  viewer: Communicator.WebViewer;
  fileType: string;
  url: string;
  reset?: boolean;
};

// const nullViewer = ({ viewer, args }: { viewer: Communicator.WebViewer; args: any }) => {};

export const switchModel = async ({ viewer, fileType, url, reset }: Props): Promise<{ viewer: Communicator.WebViewer; nodeID: number[] }> => {
  // const camera = viewer.view.getCamera();
  // await viewer.model.clear();

  const n = viewer.model.createNode(undefined, fileType);
  // viewer.model.getNodeNetMatrix()
  const nodeID = await viewer.model.loadSubtreeFromScsFile(n, url, null);
  // if(!reset) {
  //   viewer.view.setCamera(camera);
  // }
  return { viewer, nodeID };
};

export const createSceneModelKey = ({ id, type }: { id: number; type: string }) => {
  return `${id}/${type}`;
};

export const getKeyComponents = (key: string): { id: number; type: string } => {
  const [oldKeyId, type] = key.split('/');
  return { id: Number(oldKeyId), type };
};
export const getParentNode = (node: number, viewer: Communicator.WebViewer): number | null => {
  const parent = viewer.model.getNodeParent(node);
  if (parent === -2) {
    return node;
  } else if (parent !== null) {
    return getParentNode(parent, viewer);
  } else {
    return null;
  }
};
export const getParentNodeName = (node: number, viewer: Communicator.WebViewer): string | null => {
  const parentNode = getParentNode(node, viewer);
  if (parentNode !== null) {
    return viewer.model.getNodeName(parentNode);
  } else {
    return null;
  }
};

export const isVisualFromNodeName = (id: string | null): boolean | null => {
  if (id) {
    return id.split('/')[1] === 'visual';
  } else {
    return null;
  }
};

const evalFuncAgainstNodeChildren = (viewer: Communicator.WebViewer, node: number, callback: (n: number) => void) => {
  const children = viewer.model.getNodeChildren(node);
  console.log(children.length);
  if (children && children.length > 0) {
    children.forEach((child) => {
      callback(child);
      return evalFuncAgainstNodeChildren(viewer, child, callback);
    });
  } else {
    return;
  }
};

export const loadModelArray = async ({ viewer, model, modelArray }: { viewer: Communicator.WebViewer; model: Model; modelArray: Uint8Array }) => {
  const nodeName = createSceneModelKey(model);
  const nodeId = viewer.model.createNode(-2, nodeName);
  await viewer.model.loadSubtreeFromScsBuffer(nodeId, modelArray);
  if (model.type === 'visual') {
    viewer.model.setNodesOpacity([nodeId], 0.5);
  }
  return { nodeName, nodeId, model };
};

export const clearScene = (viewer: Communicator.WebViewer, nodeId: number, visual = true): Promise<void>[] => {
  console.log('clearing');
  const deleteStack: Promise<void>[] = [];
  const nukeEverything = (nodeId: number): void => {
    const children = viewer.model.getNodeChildren(nodeId);
    if (!children[0]) {
      return;
    } else {
      children.map((v) => {
        deleteStack.push(viewer.model.deleteNode(v));
        return nukeEverything(v);
      });
    }
  };
  nukeEverything(nodeId);
  return deleteStack;
};

export type SetViewer = {
  viewer: Communicator.WebViewer;
  view: keyof typeof Communicator.ViewOrientation; //"Iso" | "Bac"| "Front" | "Bottom"|"Left"|"Right"| "Top"
};

export const setView = (viewer: Communicator.WebViewer, view: keyof typeof Communicator.ViewOrientation) => {
  viewer.setViewOrientation(Communicator.ViewOrientation[view], 500);
  return viewer;
};

export const setPers = (viewer: Communicator.WebViewer, projection: Communicator.Projection) => {
  viewer.view.setProjectionMode(projection);
  return viewer;
};

export function axisTriad(viewer: Communicator.WebViewer) {
  const axisTriad = viewer.view.getAxisTriad();
  if (!axisTriad.getEnabled()) {
    axisTriad.setAnchor(Communicator.OverlayAnchor.LowerRightCorner);
    axisTriad.enable();
  } else {
    axisTriad.disable();
  }
}

export function ViewCube(viewer: Communicator.WebViewer) {
  const navCube = viewer.view.getNavCube();
  navCube.setAnchor(Communicator.OverlayAnchor.LowerRightCorner);
  navCube.enable();
  return viewer;
}

// TODO move to async dispatch
export const ToggleNodeVisibility = (viewer: Communicator.WebViewer, nodeId: number) => {
  return viewer.model.setNodesVisibility([nodeId], !viewer.model.getNodeVisibility(nodeId));
};

export const fitModel = (viewer: Communicator.WebViewer) => {};
export function ToggleCubeToTriad(viewer: Communicator.WebViewer, position: Communicator.OverlayAnchor = Communicator.OverlayAnchor.LowerRightCorner) {
  const axisTriad = viewer.view.getAxisTriad();
  const navCube = viewer.view.getNavCube();
  if (axisTriad.getEnabled()) {
    axisTriad.disable();
    navCube.setAnchor(position);
    navCube.enable();
  } else {
    navCube.disable();
    axisTriad.setAnchor(position);
    axisTriad.enable();
  }
  return viewer;
}
