// @flow
import * as React from 'react';
import { useEffect, useState } from "react";
import {UserPopUp} from "../../components/molecules/UserPopUp";
import {CreateProject, } from "../../components/organisms/CreateProject";
import {Eula} from "./Eula";
import User from "../../components/atoms/User";
import { useStore } from "../../store/projectStore";

import {
    useGetProjectsQuery,
    useGetUserQuery,
} from "../../generated/clientTypes";
import { Project } from "./SelectProject";
import { getCurrentUser } from "../../helpers/useUser";

type Props = {};

export function Projects(props: Props) {
    useEffect( () => {
        (async ()=>{
            await getCurrentUser()
        })();
    }, []);
    const [showArchive, setShowArchive] = useState(false);
    const [userPopup, setUserPopup] = useState(false);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const user = useStore(store=>store.user);
    const {data:userInfo, refetch:userRefetch} = useGetUserQuery({variables:{user}})
    const {data:projectData, refetch:refetchProjects}  = useGetProjectsQuery({variables:{user}, fetchPolicy:"network-only"});
    function handleCreateProject() {
        setShowCreateProject(false);
        refetchProjects()
    }
    // const showUser = userPopup || userInfo === undefined || userInfo.state ==="new";
    // TODO replace with suspense
    if (userInfo === null || !userInfo) {
        return (
          <div className="w-screen max-w-screen h-screen max-h-screen bg-menuBackground flex justify-center items-center">
              <div className="animate-spin rounded-full w-96 h-96 border-b-2 border-highPriority"/>
          </div>
          )
    }

    return (
        <div className="w-screen max-w-screen h-screen max-h-screen bg-menuBackground">
                <div className="min-h-screen bg-menuBackground text-highPriority text-highPriority font-maven-pro">
                    <div className="pt-10">
                        <div className="max-w-5xl h-20 mx-auto flex flex-auto content-center">
                            <User onClick={()=>setUserPopup(prev=>!prev)} user={userInfo.getUser.initials}/>
                            <div className="flex-grow"></div>
                            <div className=" mr-6">
                                <button
                                    className={`focus:outline-none text-icon font-bold py-1 px-8 border-2 border-highPriority text-highPriority hover:border-highlight hover:text-menuBackground hover:bg-highlight ${showCreateProject ? "border-highlight text-highlight" : ""}`}
                                    onClick={() => setShowCreateProject(prev => !prev)}>
                                    NEW PROJECT
                                </button>
                            </div>
                            <div className=" mr-6">
                                <button
                                    className={`focus:outline-none text-icon font-bold py-1 px-8 border-2 border-highPriority text-highPriority hover:border-highlight hover:text-menuBackground hover:bg-highlight ${showArchive ? "border-highlight text-highlight" : ""}`}
                                    onClick={() => setShowArchive(prev => !prev)}>
                                    {showArchive ? "HIDE ARCHIVE" : "SHOW ARCHIVE"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='items-center overflow-x-auto w-full pt-10'>
                        <table
                            className={`bg-menuBackground mx-auto max-h-screen max-w-5xl w-full whitespace-nowrap bg-white divide-y ${showArchive ? "text-highlight divide-highlight" : "text-highPriority divide-highPriority"} overflow-hidden`}>
                            <thead className="block w-full">
                            <tr className={`flex w-full font-semibold text-sm uppercase text-center`}>
                                <td className="py-4 w-2/5 px-9 text-left">
                                    PROJECT
                                </td>
                                <td className="py-4 w-1/5">
                                    OWNER
                                </td>
                                <td className="py-4 w-1/5">
                                    CREATED
                                </td>
                                <td className="py-4 w-1/5">
                                    {showArchive ? "UN-ARCHIVE" : "ARCHIVE"}
                                </td>
                            </tr>
                            </thead>
                            <tbody
                                className="block w-full overflow-y-scroll no-scrollbar">
                            {projectData ? projectData.listProject?.slice()
                                .sort((a, b) => new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime())
                                .map((val: any) =>((val.archive && showArchive) || (!val.archive && !showArchive)) ?
                                     <Project key={val.user + val.project} {...{
                                         initial: val.user.slice(0, 2),
                                         project: val.project,
                                         created: new Date(val.createdAt),
                                         updated: new Date(val.updatedAt),
                                         user: val.user,
                                         status: val.status,
                                         archive: val.archive,
                                         refetch: () => refetchProjects()
                                     }}/> : null
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                    {showCreateProject ? <CreateProject user={user} showPopup={handleCreateProject}/> : null}
                    {userInfo ? <Eula userInfo={userInfo} refetch={userRefetch}/>: null}
                    {userPopup && userInfo ?
                        <UserPopUp refetch={userRefetch} userInfo={userInfo}
                                   showPopup={() => setUserPopup(false)}/> : null}

                </div>
        </div>
    );
};
