// @flow
import * as React from 'react';

type Props = {
  size?:string;
  onClick?: ( arg: React.MouseEvent<HTMLButtonElement | HTMLDivElement>)=>void;
  user: string;
};

function User({  user, onClick}: Props) {
  return (
      <button onClick={onClick} className="h-20 w-20 rounded-full border-2 border-highPriority text-title font-bold focus:outline-none hover:text-menuBackground hover:bg-highlight hover:border-highlight">{user.toUpperCase()}</button>
  );
}

export default User;
