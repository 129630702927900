import { StateStorage } from 'zustand/middleware';
import { del, get, set } from 'idb-keyval';

const env = process.env.LOG === 'prod';

export const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    if (env) console.log(name, 'has been retrieved');
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    if (env) console.log(name, 'with value', value, 'has been saved');
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    if (env) console.log(name, 'has been deleted');
    await del(name);
  },
};
