// @flow
import * as React from "react";
import { useState } from "react";
import { CopyArrayElement } from "../molecules/CopyArrayElement";
import { useStore } from "../../store/projectStore";
import { AnalysisTypes } from "../../generated/clientTypes";
import SvgButton from "../atoms/SvgButton";
import StartIcon from "../assets/StartIcon";


type Props = {
  refetchIgnoreCache: () => Promise<void>;
};

function Analysis({ refetchIgnoreCache }: Props) {
  const [expanded, setExpanded] = useState(true);
  const [runAllLoading, setRunAllLoading] = useState(false);
  const [view, setView] = useState({
    curvature: true,
    depth: false,
    facet: false,
    undercut: false,
    shelf: false
  });
  const [analyze, runAllAnalysis] = useStore(store => [store.analyze, store.runAllAnalysis]);
  const curvature = useStore(store => store.curvature);
  const depth = useStore(store => store.depth);
  const undercut = useStore(store => store.undercut);
  const shelf = useStore(store => store.shelf);
  const facet = useStore(store => store.facet);
  const content = {
    curvature: "Calculates radius on surface & angle between surfaces. Radii less than 6in (153mm) angles less than 60 degrees are out of specification.",
    depth: "Calculates distance to backplane. Any depth greater than 12in (304mm) or less than 4in (102mm) is out of specification.",
    facet: "Calculates feature face surface area. Surfaces less than 3ft^2 are out of specification.",
    shelf: "Detects flat surfaces that would not shed water. Pitches less than 4:12 are out of specification.",
    undercut: "Detects surfaces outside the bounds of the backplane (overlap) and undercut features."
  };
  const handleAnalyze = (t: AnalysisTypes) => {
    analyze(t, refetchIgnoreCache).catch(console.log);
  };
  const handleRunAll = async () => {
    setRunAllLoading(true);
    await runAllAnalysis(() => {
      setRunAllLoading(false);
      refetchIgnoreCache();
    });
  };
  return (
    <div className="bg-menuBackground w-60 m-2">
      <div className="w-full p-1">
        <div className="flex flex-auto items-center border-b-2 border-highPriority">
          <div className="pl-4">
            {runAllLoading ?
              <div className="animate-spin rounded-full w-2.5 h-2.5 border-b-2 border-highPriority" />
              :
              < SvgButton onClick={() => handleRunAll()} active={false} SvgSource={StartIcon}
                          css={`h-3 w-3 focus:outline-none`} />
            }
          </div>
          <p className="w-full text-right text-title py-0.5 px-2" onClick={() => setExpanded(!expanded)}>
            ANALYSIS
          </p>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <CopyArrayElement title={"Curvature"}
                          active={curvature?.status === "loading"}
                          arrowButtonOnClick={() => handleAnalyze("curvature")}
                          barOnClick={() => setView(view => ({ ...view, curvature: !view.curvature }))}
                          content={content.curvature}
                          flash={curvature?.status !== "new" ? curvature?.status : ""}
                          showContent={view.curvature}
                          percentage={curvature?.percentComplete}/>
        <CopyArrayElement title={"Facet"}
                          active={facet?.status === "loading"}
                          arrowButtonOnClick={() => handleAnalyze("facet")}
                          barOnClick={() => setView(view => ({ ...view, facet: !view.facet }))}
                          flash={facet?.status !== "new" ? facet?.status : ""}
                          content={content.facet}
                          showContent={view.facet}
                          percentage={facet?.percentComplete}/>
        <CopyArrayElement title={"Depth"}
                          active={depth?.status === "loading"}
                          arrowButtonOnClick={() => handleAnalyze("depth")}
                          barOnClick={() => setView(view => ({ ...view, depth: !view.depth }))}
                          flash={depth?.status !== "new" ? depth?.status : ""}
                          content={content.depth}
                          showContent={view.depth}
                          percentage={depth?.percentComplete}/>
        <CopyArrayElement title={"Undercut"}
                          active={undercut?.status === "loading"}
                          arrowButtonOnClick={() => handleAnalyze("undercut")}
                          barOnClick={() => setView(view => ({ ...view, undercut: !view.undercut }))}
                          content={content.undercut}
                          showContent={view.undercut}
                          flash={undercut?.status !== "new" ? undercut?.status : ""}
                          percentage={undercut?.percentComplete}/>

        <CopyArrayElement title={"Shelf"}
                          active={shelf?.status === "loading"}
                          arrowButtonOnClick={() => handleAnalyze("shelf")}
                          barOnClick={() => setView(view => ({ ...view, shelf: !view.shelf }))}
                          content={content.shelf}
                          showContent={view.shelf}
                          flash={shelf?.status !== "new" ? shelf?.status : ""}
                          percentage={shelf?.percentComplete}/>
      </div>
    </div>
  );
}

export default Analysis;
