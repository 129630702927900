export function downloadBlob(response: any, name: string) {
  const url = window.URL.createObjectURL(new Blob([response]));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function downloadSignedUrl(url: string, type: string, name: string) {
  return fetch(url)
    .then((r): Promise<string | Blob> => (type === 'input' ? r.text() : r.blob()))
    .then((response: any) => {
      downloadBlob(response, name);
    });
}

export const uploadSignedUrl = (url: string, body: any): Promise<Response> => {
  return fetch(url, {
    method: 'PUT',
    body: body,
    headers: { 'Content-Type': 'application/octet-stream' },
  });
};
