// @flow
import * as React from 'react';
import {useState} from "react";
import { useStore } from "../../store/projectStore";

type Props = {};


const colors =
    {
        menuBackground: '#1f2126',
        mediumPriority: '#e5e5e5',
        highPriority: '#edeef3',
        highlight: '#b6eb1a',

        obsidian: '#09090a',
        onyx: '#151619',
        carbon: '#1f2126',
        stone: '#343740',
        pewter: '#aeafb2',
        puddy: '#edeef3',

        clay: '#ff7576',
        vapor: '#31c7f5',
        kinetic: '#b6eb1a',
    }

export function Colorbar(props: Props) {
    const [info, setInfo] = useState(" ");

    const currentModel = useStore(store=>store.currentModel)
    function gradientSwitch(curentType: string) {
        switch (curentType) {
            case("curvature" || "new"): {
                return (
                    <>
                        <svg width="100%" height="100%" viewBox="0 0 128 20" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="myGradient">
                                    <stop offset="30%" stopColor={colors.puddy}/>
                                    <stop offset="65%" stopColor={colors.vapor}/>
                                    <stop offset="70%" stopColor={colors.clay}/>
                                </linearGradient>
                            </defs>
                            <rect width="128" height="20" fill="url('#myGradient')"/>
                        </svg>
                        <div className="fixed -mt-5 flex flex-row h-5 w-32 z-50">
                            <div className="h-5 w-1/3" onMouseEnter={() => setInfo("within bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                            <div className="h-5 w-1/3" onMouseEnter={() => setInfo("edge of bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                            <div className="h-5 w-1/4" onMouseEnter={() => setInfo("out of bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                        </div>
                    </>
                )
            }
            case ("shelf"):
            case("facet"): {
                return (
                    <>
                        <svg width="100%" height="100%" viewBox="0 0 128 20" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="myGradient">
                                    <stop offset="45%" stopColor={colors.puddy}/>
                                    <stop offset="45%" stopColor={colors.clay}/>
                                </linearGradient>
                            </defs>

                            <rect width="128" height="20" fill="url('#myGradient')"/>
                        </svg>
                        <div className="fixed -mt-5 flex flex-row h-5 w-32 z-50">
                            <div className="h-5 w-1/2" onMouseEnter={() => setInfo("within bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                            <div className="h-5 w-1/2" onMouseEnter={() => setInfo("out of bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                        </div>
                    </>
                )
            }
            case ("undercut"):
            case("depth"): {
                return (
                    <>
                        <svg width="100%" height="100%" viewBox="0 0 128 20" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="myGradient">
                                    <stop offset="30%" stopColor={colors.puddy}/>
                                    <stop offset="40%" stopColor={colors.kinetic}/>
                                    <stop offset="60%" stopColor={colors.kinetic}/>
                                    <stop offset="70%" stopColor={colors.clay}/>
                                </linearGradient>
                            </defs>

                            <rect width="128" height="20" fill="url('#myGradient')"/>
                        </svg>
                        <div className="fixed -mt-5 flex flex-row h-5 w-32 z-50">
                            <div className="h-5 w-1/3" onMouseEnter={() => setInfo("within bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                            <div className="h-5 w-1/3" onMouseEnter={() => setInfo("edge of bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                            <div className="h-5 w-1/3" onMouseEnter={() => setInfo("out of bounds")}
                                 onMouseLeave={() => setInfo(" ")}/>
                        </div>
                    </>
                )
            }
        }
    }

    return (
        <div className="fixed left-1/2 bottom-14">
            <div className="text-menuBackground text-center">{info}</div>
            <div className={`h-5 w-32`}>
                {currentModel ? gradientSwitch(currentModel): null}
            </div>
        </div>
    );
};