// @flow
import * as React from 'react';
import AnalysisIcon from '../assets/AnalysisIcon';
import SideBarIcon from '../molecules/SideBarIcon';
import {PanelState} from '../../routes/modeler/Modeler.view';
import FilesIcon from '../assets/FilesIcon';
import {useNavigate} from 'react-router-dom';
import StatsIcon from "../assets/StatsIcon";
import ViewIcon from "../assets/ViewIcon";
import UploadIcon from "../assets/UploadIcon";
import BranchIcon from "../assets/BranchIcon";
import SvgButton from "../atoms/SvgButton";

type Props = {
    currentPanel: PanelState;
    updatePanel: { (action: keyof PanelState): void };
};

function MenuBar({updatePanel, currentPanel}: Props) {
    const history = useNavigate();

    return (
        <div>
            <div
                className="absolute bg-menuBackground w-screen text-highPriority inline-flex flex-row p-2 h-16 space-x-6">
                <div className='has-tooltip'>
                    <span
                        className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>Projects</span>
                    <SvgButton css="w-12 h-12" SvgSource={BranchIcon} onClick={() => history("/client/projects")}/>
                </div>
                <div className='has-tooltip'>
                    <span
                        className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>Upload</span>
                    <SideBarIcon title={'UPLOAD'} active={currentPanel['UPLOAD']} updatePanel={updatePanel}
                                 icon={UploadIcon}/>
                </div>
                <div className='has-tooltip'>
                    <span
                        className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>Inventory</span>
                    <SideBarIcon title={'FILES'} active={currentPanel['FILES']} updatePanel={updatePanel}
                                 icon={FilesIcon}/>
                </div>
                <div className='has-tooltip'>
                    <span
                        className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>View</span>
                    <SideBarIcon title={'VIEW'} active={currentPanel['VIEW']} updatePanel={updatePanel}
                                 icon={ViewIcon}/>
                </div>
                <div className='has-tooltip'>
                    <span
                        className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>Analysis</span>
                    <SideBarIcon title={'ANALYSIS'} active={currentPanel['ANALYSIS']} updatePanel={updatePanel}
                                 icon={AnalysisIcon}/>
                </div>
                <div className='has-tooltip'>
                    <span className='tooltip rounded shadow-lg p-1 bg-menuBackground text-highPriority bg-opacity-50 mt-16'>Metrics</span>
                    <SideBarIcon title={'STATS'} active={currentPanel['STATS']} updatePanel={updatePanel}
                                 icon={StatsIcon}/>
                </div>
            </div>
        </div>
    );
}

export default MenuBar;
