import { FileTypes } from "../../generated/clientTypes";


type FileSwitchElem = {
    vizName: "model.scs" | "curvature.scs" | "depth.scs" | "facet.scs" | "shelf.scs" | "undercut.scs" | "visual.scs"
    name: FileTypes;
    downloadName: string;
}

type FilesSwitch =
    [FileSwitchElem, FileSwitchElem, FileSwitchElem, FileSwitchElem, FileSwitchElem, FileSwitchElem]

export const tempNameSwitch: FilesSwitch = [
    {
        vizName: "model.scs",
        name: "model",
        downloadName: "model.stp",
    },
    {
        vizName: "curvature.scs",
        name: "curvature",
        downloadName: "curvature.zip",

    },
    {
        vizName: "depth.scs",
        name: "depth",
        downloadName: "depth.zip",

    },
    {
        vizName: "facet.scs",
        name: "facet",
        downloadName: "facet.zip",
    },
    {
        vizName: "undercut.scs",
        name: "undercut",
        downloadName: "undercut.zip",
    },
    {
        vizName: "shelf.scs",
        name: "shelf",
        downloadName: "shelf.zip",
    },

];