import React from 'react';

type Props = {
  hover: boolean;
};

function AnalysisIcon({ hover }: Props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 499.84 445" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M493.67,409.14,351.11,266.87a160.18,160.18,0,0,0,33.31-97.93c0-88.75-72.21-161-161-161s-161,72.21-161,161,72.21,161,161,161a160.14,160.14,0,0,0,98-33.34L464,438.86a21,21,0,1,0,29.67-29.72Zm-342-145.35.7-.64a12.55,12.55,0,1,0-16.83-18.62l-2.23,2A118.9,118.9,0,0,1,305.45,82.83l-3.72,2.24a12.55,12.55,0,1,0,13,21.5l5.32-3.2,1.89-1.14a118.72,118.72,0,0,1-11.84,148.18,20.09,20.09,0,0,0-2.82,2.35,21.06,21.06,0,0,0-2.32,2.79,118.73,118.73,0,0,1-153.2,8.24Z"
        fill={hover ? '#B6EB1A' : '#E5E5E5'}
      />
        <path
            d="M211.83,174.84a12.54,12.54,0,0,0-17.63,2c-4.43,5.55-8.84,11.41-13.09,17.4-5.07,7.13-9.81,13.35-14.49,19a12.55,12.55,0,1,0,19.35,16c5.07-6.13,10.17-12.82,15.6-20.46,4-5.62,8.11-11.1,12.25-16.29A12.55,12.55,0,0,0,211.83,174.84Z"
            fill={hover ? '#B6EB1A' : '#E5E5E5'}
        />
        <path
            d="M262.28,110.88c-5.17,3.86-10.11,7.82-15.1,12.08-6.06,5.19-13.18,11.34-20.92,18.75a12.55,12.55,0,1,0,17.35,18.13c7.28-7,14.08-12.84,19.88-17.8,4.57-3.91,9.09-7.52,13.81-11a12.55,12.55,0,0,0-15-20.11Z"
            fill={hover ? '#B6EB1A' : '#E5E5E5'}
        />
      <path
          d="M0,319l.67,31C46,349,86.94,333.75,116,307l-21-22.8C71.83,305.52,37.22,318.19,0,319Z"
          fill={hover ? '#B6EB1A' : '#E5E5E5'}
      />
      <path
          d="M486.34,31V0C453.23,0,384.82,23.16,353.25,45.05l17.67,25.47C397.55,52.05,459,31,486.34,31Z"
          fill={hover ? '#B6EB1A' : '#E5E5E5'}
        />
    </svg>
  );
}

export default AnalysisIcon;
