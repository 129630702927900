import React from 'react';
import './generated/tailwind.css';
import Amplify from '@aws-amplify/core'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import ResetPassword from "./routes/login/ResetPassword";
import {Projects} from "./routes/projects/Projects";
import Login from "./routes/login/LoginForm";
import ForgotPassword from "./routes/login/ForgotPassword";
import Modeler from "./routes/modeler/Modeler";
import {PrivateRoute} from "./helpers/PrivateRoute";
import { Auth, Logger } from "aws-amplify";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ApolloLink, createHttpLink, ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const awsConfig = {
    aws_app_analytics: 'enable',
    aws_user_pools: 'enable',
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
    aws_user_pools_mfa_type: 'OFF',
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    aws_user_settings: 'enable',
};
// start up amplify
Amplify.configure(awsConfig);
Amplify.Logger.LOG_LEVEL = 'INFO';
const url = process.env.REACT_APP_API_URL;
const region = process.env.REACT_APP_REGION
const auth:AuthOptions = {
    type:"AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () => Auth.currentSession().then((r) => r.getIdToken().getJwtToken())
};

console.log(region)
console.log(url)
if(!url || !region){
    throw Error("missing creds")
}

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

export const logger = new Logger('login');

function App() {
    return (
      <ApolloProvider client={apolloClient }>
            <Router>
                <Routes>
                    <Route path="/" element={ <Login/>}/>
                    <Route path="/client"element={ <Login/>}/>
                    <Route path="/client/login"element={ <Login/>}/>
                    <Route path="/client/forgotPassword"element={ <ForgotPassword/>}/>
                    <Route path="/client/resetPassword"element={ <ResetPassword/>}/>
                    <PrivateRoute path="/client/projects" element={ <Projects/>}/>
                    <PrivateRoute path="/client/modeler" element={ <Modeler/>}/>
                    {/*<Route component={Login}/>*/}
                </Routes>
            </Router>
      </ApolloProvider>
    );
}

export default App
