import React from 'react';

type Props = {
    hover: boolean;
};

function StatsIcon({hover}: Props) {
    return (
        <svg version="1.1" id="Capa_1" fill="none" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 443.68 448.18">
            <path
                d="M428.79,79.62,180.55,1a21.31,21.31,0,0,0-16.48,1.51L11.31,83.88A21.35,21.35,0,0,0,0,102.72V364.44a21.33,21.33,0,0,0,13.27,19.75L166,446.59a21.41,21.41,0,0,0,8.07,1.59,21.67,21.67,0,0,0,5.42-.7l248.24-65.15a21.34,21.34,0,0,0,15.92-20.64V100A21.34,21.34,0,0,0,428.79,79.62ZM42.68,115.53,152.76,56.89V395.06l-110.08-45ZM401,345.22,195.45,399.17V50.49L401,115.6Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
        </svg>
    );
}

export default StatsIcon;
