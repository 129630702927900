import React from 'react';

type Props = {
    hover: boolean;
};

function DownloadIcon({hover}: Props) {
    return (
        <svg version="1.1" id="Capa_1" fill="none" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 369.93 263.42">
            <path
                d="M369.77,188.51v-.91q0-3.89-.08-7.86l-.06-5.94H333.2v51.4H36.46V173.83H0v6c0,14.67,0,30.29,0,45.8.06,24,13.93,37.72,38.06,37.72H308.3l8.61,0h.23c2.75,0,5.5,0,8.25,0,4.49,0,8.17-.05,11.58-.14,17.07-.48,31.61-13.52,32.4-29.07C370.13,219.13,370,204.25,369.77,188.51Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
            <path
                d="M110.17,142.49c.71.57,1.31,1.07,1.94,1.53l12.14,9q24,17.88,48.05,35.73c4.28,3.18,8.33,4.76,12.39,4.76s8.21-1.61,12.56-4.84q17.67-13.11,35.28-26.25l12.53-9.33,10.5-7.83,6.82-5.1-22.33-29.56-2.12,1.57c-11.66,8.67-22.74,16.9-34.86,25.72v-.54c.28-15.4.34-113.85.34-137.39H166c0,16.64,0,120.07,0,137.85l-36.61-27.21-22.24,29.42,1.9,1.52Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
        </svg>
    );
}

export default DownloadIcon;
