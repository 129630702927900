import React, { useEffect,  useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import LoginInput from "../../components/atoms/LoginInput";
import Copywrite from "../../components/atoms/Copywrite";
import SvgButton from "../../components/atoms/SvgButton";
import BranchIcon from "../../components/assets/BranchIcon";
import { getCurrentUser } from "../../helpers/useUser";
import { login } from "../../store/api";

export default function Login() {
    const {state} = useLocation();
    const history = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [failedAuth, setFailedAuth] = useState("");
    const stateFrom = state ? state.pathname : {pathname: "/client/projects"};

    const year = new Date().getFullYear();

    useEffect( () => {
        (async ()=>{
            if(await getCurrentUser()){
                history(stateFrom ? stateFrom : "/client/projects");
            }
        })();
    }, []);


    function validateLoginForm(){
        if (username.length < 3){
            setFailedAuth("did you forget your username");
            return false;
        } else if (password.length < 8){
            setFailedAuth("did you forget your password?");
            return false;
        }
        setFailedAuth("ready to login");
        return true;
    }

    async function handleLogin() {
        if (validateLoginForm()) {
            const tokenData = await login({username, password});
            console.log(tokenData)
            switch (tokenData.state) {
                case "Success": {
                    history(stateFrom ? stateFrom : "/client/projects");
                    return
                }
                case "UserNotFound":
                case "NotAuthorized": {
                    setFailedAuth(tokenData.message);
                    return
                }
                case "NewPass": {
                    setFailedAuth(tokenData.message);
                    history("/client/resetPassword");
                    return
                }
                case "UserNotConfirmed": {
                    setFailedAuth(tokenData.message)
                    history("/client/resetPassword");
                    return
                }

            }
        }
    }
    return (
        <div className="flex h-screen bg-menuBackground text-highPriority font-maven-pro">
            <div
                className="flex m-auto max-w-xs max-h-xs items-center place-content-center bg-menuBackground overflow-hidden">
                <div className="max-w-md w-full space-y-8 content-center items-center">
                    <div className="w-full flex justify-center">
                        <SvgButton css="w-24 h-24"SvgSource={BranchIcon} />
                    </div>
                    <form className="px-12 mb-4">
                        <div className="mb-6">
                            <LoginInput labelName={"username"} value={username}
                                        handleInputChange={(e) => setUsername(e.target.value)}
                                        handleEnterPressed={() => handleLogin()}
                                        className={"px-2 py-1"}
                            />
                        </div>
                        <div className="mb-6">
                            <LoginInput labelName={"password"} value={password}
                                        type={"password"}
                                        handleInputChange={(e) => setPassword(e.target.value)}
                                        handleEnterPressed={() => handleLogin()}
                                        className={"px-2 py-1"}
                            />
                        </div>
                        <div className="mb-6">
                            <button
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleLogin();
                                    }
                                }}
                                className="text-menuBackground w-full bg-highlight py-2 px-4 focus:outline-none focus:shadow-outline"
                                type="button" onClick={() => handleLogin()}>
                                SIGN IN
                            </button>
                        </div>
                        <p className="text-center text-highPriority text-base py-2">
                            {failedAuth !== "" ? failedAuth : " "}
                        </p>
                        <div className="my-3">
                            <button
                                className={`w-full text-xs bg-none py-2 px-4 text-lg focus:outline-none focus:shadow-outline ${failedAuth !== "" ? "text-highlight" : "text-highPriority"}`}
                                type="button"
                                onClick={() => history("/client/forgotPassword")}>
                                FORGOT PASSWORD?
                            </button>
                        </div>

                    </form>
                    <Copywrite year={year} />
                </div>
            </div>
        </div>
    );
}
