import React from 'react';

type Props = {
    hover: boolean;
};

function StatsIcon({hover}: Props) {
    return (
        <svg version="1.1" id="Capa_1" fill="none" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 569.24 406.26">
            <path
                d="M478.38,132a11.57,11.57,0,0,1-7.22-7.08C437.29,43.21,353.33-8.28,265.64,1.1c-80,8.56-136.44,51.09-169.52,124.56A12.37,12.37,0,0,1,90,131.54,141.85,141.85,0,0,0,2,287.27C12.6,351,70.59,403,135.23,405.53c28.29,1.14,56.64.45,85,.56a23.32,23.32,0,0,0,3.39-.51V365.2h-6.18c-23.82.32-47.64.49-71.45.22a128.19,128.19,0,0,1-22.55-2.19C80.21,354.91,44.57,315.86,41,273.3c-4.11-48.55,25.71-92.36,72.68-106.19,9.37-2.76,14.75-8.25,18.2-17.32,23.78-62.38,69-99,135-107.54,74.33-9.64,145.63,35.57,170.8,106.56,3.55,10,9.46,15.61,19.59,18.67C502,181,533.88,225.11,527.74,275c-6.4,52.13-51.11,90.42-103.66,90.49q-48.18.06-96.35,0c-14.83,0-22.76-7.88-22.78-22.65q0-36.53,0-73.07c0-1.52.15-3.05.27-5.46l48.34,36.19,24.26-32.35c-1.54-1.24-2.62-2.17-3.76-3q-37.87-28.39-75.77-56.78c-9.69-7.24-17.89-7.19-27.66.11Q240.51,231,210.46,253.59l-19.27,14.48,24.33,32.44c16-12,31.75-23.77,49.07-36.41v7c-.43,23.64-.45,47.27-.43,70.91,0,38,25.88,64,63.77,64.13,30.85.08,61.71.36,92.55-.16a184.87,184.87,0,0,0,36.47-3.8c60.89-13.44,107.25-66.32,111.9-128.43C573.36,213.51,538.19,155.19,478.38,132Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
        </svg>
    );
}

export default StatsIcon;
