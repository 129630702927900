import { Auth } from 'aws-amplify';
import { getCurrentUser } from '../helpers/useUser';

export const checkAmplify = async () => {
  try {
    const session = await Auth.currentSession();
    console.log(session);
    const user = await Auth.currentUserInfo();
    console.log(user);
    return { session, user };
  } catch (e) {
    console.log(e);
    return false;
  }
};

// export const persistToken = async () => {
//     const auth = await checkAmplify();
//     if (auth) {
//         const {session, user} = auth;
//         queryClient.setQueryData("session", session);
//         queryClient.setQueryData("user", user);
//         await queryClient.invalidateQueries('user');
//         await queryClient.invalidateQueries('session');
//         return true;
//     } else {
//         return false;
//     }
// };

const postLogin = async ({ password, username }: { password: string; username: string }): Promise<null | any> => {
  try {
    return await Auth.signIn(username, password);
  } catch (err) {
    console.log(err);
    return err;
  }
};

type LoginResponse = {
  state: 'Failure' | 'Success' | 'NewPass' | 'UserNotConfirmed' | 'UserNotFound' | 'NotAuthorized';
  message: string;
};
export const login = async ({ username, password }: { username: string; password: string }): Promise<LoginResponse> => {
  const tokenData = await postLogin({ username, password });
  if (!tokenData.success && tokenData?.code === 'UserNotConfirmedException') {
    return { message: 'new password required', state: 'UserNotConfirmed' };
  }
  if (!tokenData.success && tokenData?.code === 'UserNotFoundException') {
    return { message: "User doesn't exist", state: 'UserNotFound' };
  }
  if (!tokenData.success && tokenData?.code === 'NotAuthorizedException') {
    return { message: 'Incorrect username or password', state: 'NotAuthorized' };
  }
  if (tokenData.challengeName === 'NEW_PASSWORD_REQUIRED') {
    return { message: 'new password required', state: 'NewPass' };
  }
  if (tokenData.username && tokenData.userDataKey) {
    // await persistToken();
    await getCurrentUser();
    return { message: 'success', state: 'Success' };
  }
  return { message: 'issue with signing in', state: 'Failure' };
};
