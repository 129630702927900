// @flow
import * as React from "react";
import MolHeader from "../molecules/MolHeader";
import { useState } from "react";
import { DotToggleElem } from "../atoms/DotToggleElem";
import shallow from "zustand/shallow";
import { useStore } from "../../store/projectStore";

type Props = {};

export function View(props: Props) {
  const [expanded, setExpanded] = useState(true);
  const colorBar = useStore(store => store.colorBar);
  const setColorBar = useStore(store => store.setColorBar);
  // const viewer = useStore(store => store.getViewer);
  const { togglePerspective, toggleTriad, perspective, axis}
    = useStore(({ setView, togglePerspective, toggleTriad, perspective, axis}) =>
    ({ setView, togglePerspective, toggleTriad, perspective, axis }), shallow);


  return (
    <div className="bg-menuBackground w-60 m-2 px-1">
      <MolHeader text={"VIEW"} active={expanded} onClick={() => setExpanded(!expanded)} />
      <div className="p-1 px-3 flex flex-col w-full">
        <div className="flex flex-col text-left text-lg">
          <DotToggleElem content={"Toggle Perspective"} onClick={() => togglePerspective()}
                         active={perspective} />
          <DotToggleElem content={"Toggle Triad"} onClick={() => toggleTriad()}
                         active={axis} />
          <DotToggleElem content={"Toggle ColorBar"} onClick={() => setColorBar(!colorBar)}
                         active={colorBar} />
          {/*<div className="flex flex-col w-full">*/}
          {/*  <p className="text-lg">Set View</p>*/}
          {/*  <div className="pl-4">*/}
          {/*    <DotToggleElem content={"Left"} onClick={() => setView("Left")} />*/}
          {/*    <DotToggleElem content={"Right"} onClick={() => setView("Right")} />*/}
          {/*    <DotToggleElem content={"Top"} onClick={() => setView("Top")} />*/}
          {/*    <DotToggleElem content={"Bottom"} onClick={() => setView("Bottom")} />*/}
          {/*    <DotToggleElem content={"Front"} onClick={() => setView("Front")} />*/}
          {/*    <DotToggleElem content={"Back"} onClick={() => setView("Back")} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
