// // A wrapper for <Route> that redirects to the login
// // screen if you're not yet authenticated.
import { Route, useNavigate } from 'react-router-dom';
import React, { useLayoutEffect } from 'react';
import { getCurrentUser } from './useUser';
import { useStore } from '../store/projectStore';

export function PrivateRoute({ children, ...rest }: any) {
  const history = useNavigate();
  const user = useStore((store) => store.user);
  useLayoutEffect(() => {
    (async () => {
      await getCurrentUser();
    })();
  }, [history]);
  return <Route {...rest} render={() => (user ? children : <>{() => history('/client')}</>)} />;
}
