// @flow
import * as React from "react";
import { useState } from "react";
import SvgButton from "../atoms/SvgButton";
import StartIcon from "../assets/StartIcon";

type Props = {
  title: string;
  content: string;
  active?: boolean;
  showContent?: boolean;
  arrowButtonOnClick?: () => void;
  barOnClick?: () => void;
  flash?: string;
  percentage: number | undefined;
};

export function CopyArrayElement({
                                   title,
                                   arrowButtonOnClick,
                                   barOnClick,
                                   content,
                                   showContent,
                                   active,
                                   flash,
                                   percentage
                                 }: Props) {
  // const [localFlash, setLocalFlash] = useState(flash);
  const [state, setState] = useState(false);
  // useEffect(() => {
  //     setLocalFlash(flash);
  //     setTimeout(()=> setLocalFlash(""), 1000);
  //     return () => {
  //
  //     };
  // }, [flash])

  return (
    <div className="p-1 px-3">
      <div className="flex flex-row">
        <button className="text-lg text-left px-2">{title}</button>
        {active ?
          <div className="flex justify-between pb-1 mt-1.5 h-3 w-64">
            <div className={`h-2.5 w-${percentage}% bg-highlight`} />
            <div className="justify-end h-2.5 w-1 bg-highlight" />
          </div>
          :
          <div className="flex justify-center pt-3 h-3 w-3 items-center" onMouseOver={() => setState(true)}
               onMouseOut={() => setState(false)}>
            <SvgButton onClick={arrowButtonOnClick} active={state} SvgSource={StartIcon}
                       css={`h-3 w-3 focus:outline-none`} />
          </div>
        }
        {/*<button className="w-full px-4 text-base text-highlight" onClick={barOnClick}>{localFlash}</button>*/}
        <button className="w-full h-3 -mt-1.5 pr-4"
                onClick={barOnClick}>
          <p className="text-right">{"..."}</p>
        </button>
      </div>
      {showContent ? <p className="pl-4 text-base">{content}</p> : null}
    </div>
  );
};
