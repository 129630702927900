import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { useStore } from '../store/projectStore';
export interface UseAuthHookResponse {
  currentUser: CognitoUser | null;
}

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  console.log("getting user")
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log(user);
    if (user) {
      // const inStoreUser = useStore.getState().user;
      const userName = user.getUsername();
      // if(user.userName !== inStoreUser){
      useStore.setState({ user: userName });
      // }
      console.log(user);
      return user;
    } else {
      return null;
    }
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

export const signOut = async () => {
  try {
    useStore.setState({ user: '' });
    await Auth.signOut();
    return;
  } catch {
    return null;
  }
};

const useAuth = (): UseAuthHookResponse => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser());
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser);
  }, []);

  return { currentUser };
};

export default useAuth;

export { getCurrentUser };
