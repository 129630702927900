import { splitFileName } from '../helpers/fileHelper';
import { FileTypes, S3Url } from '../generated/clientTypes';
import { Model } from './projectStore';

export const parseKeyToModel = (key: string): Model => {
  const { file, inc } = splitFileName(key);
  const id = Number(inc);
  return { id, type: file as FileTypes, key };
};

export const downloadToIntArray = async (url: string) => {
  const down: Response = await fetch(url);
  const blob = await down.blob();
  const arrayBufferNew = await blob.arrayBuffer();
  return new Uint8Array(arrayBufferNew);
};

export const getSceneModel = async ({ url, key }: S3Url) => {
  const model = parseKeyToModel(key);
  const modelArray = await downloadToIntArray(url);
  return { model, modelArray };
};

// currently the buffers are small enough where keeping them in the store should be a non - issue
// if that changes - switch back to just holding urls and add a refetch in case it expires
export const getListSceneModels = async (parsedModels: Array<S3Url>) => {
  const modelsRequests = parsedModels.map(getSceneModel);
  return await Promise.all(modelsRequests);
};

// // TODO clear files from persist
// export const parseS3UrlArray = (parsedModels: Array<{ __typename?: "S3Url"; url: any; key: string }>, visual = false) => {
//   const models: UrlSceneModels = parsedModels.reduce((a, { url, key }: S3Url) => {
//     const [id, model] = parseKeyToModel(key, visual);
//     return { ...a, [id]: { url: url as string, model } };
//   }, {});
//   return models;
// };
