// @flow
import * as React from 'react';
import {useLayoutEffect, useState} from 'react';
import MolHeader from '../molecules/MolHeader';
import { EventStatus, useStore } from "../../store/projectStore";
import { FilesListAllQuery, useGetUserQuery } from "../../generated/clientTypes";

type Props = {
    data: FilesListAllQuery | undefined
}
function Metrics({data}:Props) {
    const [expanded, setExpanded] = useState(true);
    const triggerPricing = useStore(store=>store.triggerPricing)
    const pricing = useStore(store=>store.pricing)
    const stats = useStore(store =>store.stats)
    const triggerStats = useStore(store => store.triggerStats)
    const user = useStore(store=>store.user)
    const project = useStore(store =>store.project)
    const {data:userInfo} = useGetUserQuery({variables:{user}})

    useLayoutEffect(() => {
        if(userInfo && data && data.filesListAll.includes("model")){
           triggerPricing();
        }
        return () => {
        };
    }, [user, project, userInfo, triggerPricing]);

    useLayoutEffect(() => {
        if (data && data.filesListAll.includes("model"))
        {
            triggerStats();
        }
        return () => {
        };
    }, [user, project, triggerStats]);


    const loader = <div className="pl-2 w-max h-max flex justify-center items-center"><div className="animate-spin rounded-full w-3 h-3 border-b-2 border-highPriority" /></div>

    const priceSwitch = {
        loading: <div className="flex flex-row"><p>Estimated cost - </p>{loader}</div>,
        success:
            <p>Estimated Cost - $ {pricing.price}</p>,
        failure:
            <p className="text-highlight">Estimated Cost - out of spec</p>,

    }

    const switchGetter = (val: EventStatus) => {
        // typeguard isn't working
        if (val in priceSwitch) {
            // @ts-ignore
            return priceSwitch[val];
        } else {
            return priceSwitch.loading
        }
    }

    const format = (val: string)=>  {
        const result = val.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
    const statsSwitch = (statKey: string, unit: string) => {
        switch (stats.status) {
            case "loading"  : {
                return <div className="flex flex-row"><p>{format(statKey)} -  </p>{loader}</div>
            }
            case "new"  : {
                return <div className="flex flex-row"><p>{format(statKey)} -  </p>{loader}</div>
            }
            case "success" : {
                // @ts-ignore
                return  <p>{format(statKey)} - {stats.stats[statKey] as string} {unit} </p>;
            }
            case "failure" : {
                return  <p>{format(statKey)} - failure</p>
            }
            default: {
                return  <p>{format(statKey)} - failure </p>
            }
        }
    }
    return (
        <div className="bg-menuBackground w-60 m-2 px-1">
            <MolHeader text={'METRICS'} active={expanded} onClick={() => setExpanded(!expanded)}/>
            <div className="p-1 px-3 flex flex-col w-full">
                <div className="flex flex-col text-left text-lg">
                    {statsSwitch("averageThickness", "in")}
                    {statsSwitch("backFaceArea", "ft²")}
                    {statsSwitch("featureFaceArea", "ft²")}
                    {statsSwitch("volume", "ft³")}
                    {userInfo ? switchGetter(pricing.status) : null}
                </div>
            </div>
        </div>
    );
}

export default Metrics;
