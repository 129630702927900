export function base64ToArrayBuffer(base64: string): Uint8Array {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

export const cubicMToF = (val: string | number) => Math.round(Number(val) * 35.3147 * 100) / 100;
export const mToI = (val: string | number) => Math.round(Number(val) * 39.3701 * 100) / 100;
export const squareMToF = (val: string | number) => Math.round(Number(val) * 10.7639 * 100) / 100;
export const roundToCents = (val: string | number) => Math.round(Number(val) * 100) / 100;
