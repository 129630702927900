import React, {ChangeEvent} from 'react';

type Props = {
    labelName: string;
    value: string;
    type?: string;
    placeHolder?: string|null;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    handleEnterPressed?: () => void;
}
const LoginInput = ({value, labelName, placeHolder=null, type = "text", handleInputChange, className, handleEnterPressed}: Props) => {
    return (
            <input
                type={type}
                id={labelName}
                className={`border-highPriority text-menuBackground border-2 w-full focus:outline-none ${className}`}
                onChange={(e) => handleInputChange(e)}
                placeholder={placeHolder?placeHolder:labelName}
                value={value}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && handleEnterPressed) {
                        handleEnterPressed();
                    }
                }}
            />
    )
}

export default LoginInput;