// @flow
import * as React from 'react';
import {eula, privacy} from "./notices"
import { GetUserQuery, useUpdateUserMutation } from "../../generated/clientTypes";

type Props = {
    userInfo:  GetUserQuery
    refetch: () => void
};

export function Eula({userInfo, refetch}: Props) {
    const [updateUser] = useUpdateUserMutation();

    const buildUpdate = (update: {eula:number} | {privacy: number}) =>{
        updateUser({variables:{user: userInfo.getUser.user, ...update}}).then((r)=> refetch());
    }

    return (
        <>
            {userInfo.getUser.eula !== eula.version ?
                <div className="h-screen w-screen absolute top-0 left-0">
                    <div
                        className="p-8 z-10 absolute top-1/2 left-1/2 h-2/3 w-2/3 transform -translate-x-1/2 items-center -translate-y-1/2 flex flex-auto flex-col bg-menuBackground text-highPriority">
                        <div className="p-2">
                            <p className="font-bold">END USER AGREEMENT</p>
                        </div>
                        <div className="overflow-scroll p2">
                            <p className="text-lg">{eula.text}</p>
                        </div>
                        <div className="p-4">
                            <button onClick={()=>buildUpdate({eula:eula.version})}
                                    className="border-2 border-highPriority text-highPriority hover:border-highlight hover:text-menuBackground hover:bg-highlight p-2 m-1">ACCEPT
                            </button>
                        </div>
                    </div>
                    <div className="absolute top-0 left-0 z-0 h-screen w-screen bg-menuBackground opacity-50"/>
                </div> : null}
            {userInfo.getUser.privacy !== privacy.version ?
                <div className="h-screen w-screen absolute top-0 left-0">
                    <div
                        className="p-8 z-10 absolute top-1/2 left-1/2 h-2/3 w-2/3 transform -translate-x-1/2 items-center -translate-y-1/2 flex flex-auto flex-col bg-menuBackground text-highPriority">
                        <div className="p-2">
                            <p className="font-bold">PRIVACY NOTICE</p>
                        </div>
                        <div className="overflow-scroll p2">
                            <p className="text-lg">{privacy.text}</p>
                        </div>
                        <div className="p-4">
                            <button onClick={()=>buildUpdate({privacy:privacy.version})}
                                    className="border-2 border-highPriority text-highPriority hover:border-highlight hover:text-menuBackground hover:bg-highlight p-2 m-1">ACCEPT
                            </button>
                        </div>
                    </div>
                    <div className="absolute top-0 left-0 z-0 h-screen w-screen bg-menuBackground opacity-50"/>
                </div> : null}
        </>
    );
}