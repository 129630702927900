import React, { useEffect, useState } from "react";
import MenuBar from "../../components/organisms/MenuBar";
import Analysis from "../../components/organisms/Analysis";
import Inventory from "../../components/organisms/Files";
import Metrics from "../../components/organisms/Metrics";
import { Upload } from "../../components/organisms/Upload";
import { View } from "../../components/organisms/View";
import { Colorbar } from "../../components/atoms/Colorbar";
import { ModelPopUp } from "../../components/organisms/ModelPopUp";
import { useFilesListAllQuery } from "../../generated/clientTypes";
import { useStore } from "../../store/projectStore";

export type PanelState = {
  UPLOAD: boolean;
  FILES: boolean;
  VIEW: boolean;
  ANALYSIS: boolean;
  STATS: boolean;
};
type PanelStateKey = keyof PanelState;

function usePanelOptions() {
  const def = { UPLOAD: false, FILES: false, VIEW: false, ANALYSIS: false, STATS: false };
  const [currentPanel, setCurrentPanel] = useState<PanelState>({ ...def });

  function updatePanel(val: PanelStateKey | PanelStateKey[]) {
    if (typeof val === "string") {
      setCurrentPanel({ ...currentPanel, [val]: !currentPanel[val] });
    } else {
      const update = val.reduce((acc, v) => {
        return { ...acc, [v]: currentPanel[v] };
      }, {});
      setCurrentPanel({ ...currentPanel, ...update });
    }
  }

  return { currentPanel, updatePanel };
}

type Props = {
  user: string;
  project: string
}

function ModelerView({ user, project }: Props) {
  const { currentPanel, updatePanel } = usePanelOptions();
  const currentModel = useStore(store => store.currentModel);
  const colorBar = useStore(store => store.colorBar);
  const modelStructureReady = useStore(store => store.modelStructureReady);
  const [getSceneModels, getSceneNodes, getVisualSceneModels] = useStore(s => [s.getSceneModels, s.getSceneNodes, s.getVisualSceneModels]);
  const { data, refetch } = useFilesListAllQuery({
    variables: {
      project,
      user,
      filter: { unique: { eq: true }, fileComponents: { type: true } },
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    (async () => {
        if (data && modelStructureReady) {
          const nodes = getSceneNodes();
          // kind of goofy logic:
          // if the scene was entered through the project page and there's no currentModel or
          // if the page was refreshed and the zustand state does not match the scene state.
          if (nodes.length === 0) {
            if (data.filesListAll.includes("model")) {
              await getSceneModels("model", false, true);
              updatePanel("FILES");
              if (data.filesListAll.includes("visual")) {
                await getVisualSceneModels();
              }
            } else if (!currentModel || data.filesListAll.length === 0) {
              updatePanel("UPLOAD");
            }
          }
        }
      }
    )();

    return () => {
      refetch({ user, project }).then(() =>{})
    };
  }, [user, project, data, modelStructureReady]);

  const onUpload = async () => {
    await refetch();
    updatePanel(["UPLOAD", "FILES"]);
  };

  const refetchIgnoreCache = async () => {
    await refetch({user, project});
  }

  return (
    <>
      <div className="cursor-pointer text-highPriority font-maven-pro">
        <MenuBar updatePanel={updatePanel} currentPanel={currentPanel} />
        <div className="absolute inline-flex flex-col max-h-5/6 p-2 mt-16 overflow-y-scroll no-scrollbar">
          {currentPanel.UPLOAD ? <Upload onUpload={onUpload} /> : null}
          {currentPanel.FILES ? <Inventory data={data} /> : null}
          {currentPanel.VIEW ? <View /> : null}
          {currentPanel.ANALYSIS ? <Analysis refetchIgnoreCache={refetchIgnoreCache} /> : null}
          {currentPanel.STATS ? <Metrics data={data} /> : null}
        </div>
        {colorBar ? <Colorbar /> : null}
        <ModelPopUp refetch={refetch} />
      </div>
    </>
  );
}

export default ModelerView;
