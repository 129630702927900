import React from 'react';

type Props = {
  hover: boolean;
};

function StartIcon({ hover }: Props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0 2.0 1.80 1.0 0.0 0.0Z" fill={hover ? '#B6EB1A' : '#E5E5E5'} />
    </svg>
  );
}

export default StartIcon;
