import React, {useState} from "react";
import LoginInput from "../../components/atoms/LoginInput";
import Copywrite from "../../components/atoms/Copywrite";
import {useFormFields} from "../../helpers/hooks";
import {useNavigate} from "react-router-dom";
import SvgButton from "../../components/atoms/SvgButton";
import BranchIcon from "../../components/assets/BranchIcon";
import Auth from "@aws-amplify/auth";

interface Fields {
    code: string;
    username: string;
    password: string;
    confirmPassword: string;
}

export default function ForgotPassword() {
    const history = useNavigate();
    const year = new Date().getFullYear();

    const [fields, handleFieldChange] = useFormFields<Fields>({
        code: "",
        username: "",
        password: "",
        confirmPassword: "",
    });

    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [failedAuth, setFailedAuth] = useState("ready to reset?");


    function validateResetForm() {
        if (fields.username.length < 3) {
            setFailedAuth("did you forget your username?")
            return false
        } else if (fields.password.length < 8) {
            setFailedAuth("did you forget your password?")
            return false
        } else if (fields.password.length < 8) {
            setFailedAuth("did you forget your password?")
            return false
        } else if (fields.password !== fields.confirmPassword) {
            setFailedAuth("passwords dont match")
            return false
        }
        setFailedAuth("ready to reset?")
        return true;
    }

    function handleSendCodeClick() {
        return Auth.forgotPassword(fields.username).then((r) => {
            setCodeSent(true);
            setFailedAuth(`sent a new verification to ${r.CodeDeliveryDetails.Destination}`);
        }).catch(error => {
            console.log(error)
            setFailedAuth(error.message);
        });
    }

    function handleResetClick() {
        if (validateResetForm()) {
            return Auth.forgotPasswordSubmit(fields.username, fields.code, fields.password).then((r => {
                setConfirmed(true);
                setFailedAuth("reset password!");
            })).catch((error: Error) => {
                setFailedAuth(error.message);
            });
        }
    }

    function handleResendClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        return Auth.resendSignUp(fields.username).then((r => {
            console.log(r);
            setFailedAuth("resent a verification code")
        })).catch((error: Error) => {
            console.log(error)
            setFailedAuth(error.message);
        });
    }

    function getResetForm() {
        return (<form className="px-12 mb-4">
            <div className="mb-6">
                <LoginInput labelName={"code"} value={fields.code}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <LoginInput labelName={"password"} type={fields.password === "PASSWORD" ? "text" : "password"}
                            value={fields.password}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <LoginInput labelName={"confirmPassword"}
                            type={fields.confirmPassword === "CONFIRM PASSWORD" ? "text" : "password"}
                            value={fields.confirmPassword}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <button
                    className="text-menuBackground w-full bg-highlight py-2 px-4 focus:outline-none focus:shadow-outline"
                    type="button" onClick={()=>handleResetClick}>
                    RESET
                </button>
            </div>
            <div className="mb-6">
                <button
                    className="text-menuBackground w-full bg-highlight py-2 px-4 focus:outline-none focus:shadow-outline"
                    type="button" onClick={()=>handleResendClick}>
                    RESEND
                </button>
            </div>
            <p className="text-center text-highlight text-lg py-2">
                {failedAuth}
            </p>
        </form>);
    }

    function getCodeForm() {
        return (<form className="px-12 mb-4 text-highPriority font-maven-pro">
            <div className="mb-6">
                <LoginInput labelName={"username"} value={fields.username}
                            handleInputChange={handleFieldChange}
                // enter seems to refresh the form... needs work
                            handleEnterPressed={()=>{console.log("this")}}
                            className={"px-2 py-1"}
                />
            </div>
            <div className="mb-6">
                <button
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSendCodeClick();
                        }
                    }}
                    className="text-menuBackground w-full bg-highlight py-2 px-4 focus:outline-none focus:shadow-outline"
                    type="button" onClick={handleSendCodeClick}>
                    RESET
                </button>
            </div>
            <p className="text-center text-highlight text-lg py-2">
                {failedAuth}
            </p>
        </form>);
    }

    function getSuccess() {
        setTimeout(() => {
            history("/projects")
        }, 1000)
        return (
            <form>
                <p className="text-center text-highlight text-lg py-2">
                    {failedAuth}
                </p>
            </form>
        );
    }

    return (
        <div className="flex h-screen bg-menuBackground">
            <div
                className="flex m-auto max-w-xs max-h-xs items-center place-content-center bg-menuBackground overflow-hidden">
                <div className="max-w-md w-full space-y-8">
                    <div className="w-full flex justify-center">
                        <SvgButton css="w-24 h-24"SvgSource={BranchIcon} />
                    </div>
                    {!codeSent
                        ? getCodeForm()
                        : !confirmed
                            ? getResetForm()
                            : getSuccess()}
                    <Copywrite year={year} />
                </div>
            </div>
        </div>

    );
}