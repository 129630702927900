type FileProps = {
  user: string;
  projectName: string;
  file: string;
  inc: string;
  ext: string;
};

// !!!! determines protected characters as / _ .
export const splitFileName = (name: string): FileProps => {
  const re = /[/|_|.]/;
  const [user, projectName, file, inc, ext] = name.split(re);
  return { user, projectName, file, inc, ext };
};
