import * as React from "react";
import { useUpdateProjectMutation } from "../../generated/clientTypes";
import { useStore } from "../../store/projectStore";
import { useNavigate } from "react-router-dom";

type ProjectType = {
  initial: string;
  project: string;
  updated: Date;
  created: Date;
  status: string;
  user: string;
  archive: boolean;
  refetch: () => void;
}

export function Project({
                          initial,
                          project,
                          updated,
                          created,
                          user,
                          archive,
                          refetch
                        }: ProjectType) {
  const hover = archive ? "hover:border-highlight" : "hover:border-highPriority";
  const setProject = useStore((store) => store.setProject);
  const history = useNavigate();

  const [archiveProject] = useUpdateProjectMutation({
    variables: {
      user,
      project,
      archive:!archive
    }
  });

  const handleArchive = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await archiveProject();
    refetch()
  };

  const selectProject = async () => {
    await setProject(project);
    history("/client/modeler");
  }

  return (
    <tr className={`flex w-full pt-4 border-2 border-menuBackground ${hover} text-highPriority`}
        onClick={() => selectProject()}>
      <td className="px-4 py-4 w-2/5">
        <div className="flex items-center space-x-3">
          <div className="inline-flex w-10 h-10">
            <div
              className={`w-10 h-10 rounded-full p-2 flex items-center justify-center bg-highlight text-menuBackground text-icon lg:focus-within:text-highPriority`}>
              {initial}
            </div>
          </div>
          <div>
            <div className="flex flex-row">
              <p className="font-semibold tracking-wide">
                {project}
              </p>
              <p className="font-semibold tracking-wide text-pewter pl-1">
                {archive ? `(archived)` : ""}
              </p>
            </div>
            <p className="text-gray-500 text-base">
              {updated.toLocaleDateString("en-US")}
            </p>
            <p className="text-gray-500 text-base">
              {updated.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })}
            </p>
          </div>
        </div>
      </td>
      <td className="text-center py-4 w-1/5">
        {user}
      </td>
      <td className="text-center py-4 w-1/5">
        <p className="text-gray-500 text-base">
          {created.toLocaleDateString("en-US")}
        </p>
        <p className="text-gray-500 text-base">
          {created.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })}
        </p>
      </td>
      <td className="text-center py-4 w-1/5">
        <button onClick={handleArchive}
                className={`${archive ? "bg-highlight hover:bg-vapor" : null} hover:bg-clay border-2 border-highPriority h-4 w-4 rounded-full`} />
      </td>
    </tr>
  );

}