// @flow
import * as React from 'react';
import SvgButton from '../atoms/SvgButton';
import { PanelState } from '../../routes/modeler/Modeler.view';

type Props = {
  title: keyof PanelState;
  active: boolean;
  updatePanel: { (action: keyof PanelState): void };
  icon: React.FC<{ hover: boolean }>;
};

function SideBarIcon({ title, active, updatePanel, icon }: Props) {
  return (
    <div>
      <SvgButton css="w-12 h-12" SvgSource={icon} active={active} onClick={() => updatePanel(title)} />
    </div>
  );
}

export default SideBarIcon;
