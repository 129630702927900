// @flow
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useStore } from "../../store/projectStore";
import { getParentNodeName, isVisualFromNodeName } from "../../helpers/ViewerEvents";

type Props = {
  refetch: () => void
};

export function ModelPopUp({ refetch }: Props) {
  const selection = useStore(store => store.selection);
  const currentModel = useStore(store => store.currentModel);
  const visual = useStore(store => store.visual);
  const [
    switchModelVisualType,
    switchModelBackface,
    getViewer
  ] = useStore(store => [store.switchModelVisualType, store.switchModelBackface, store.getViewer]);
  const [view, setView] = useState(false);
  const [selectionProps, setSelectionProps] = useState({ backface: false, exchangeId: "", visual: false });
  const [loading, setLoading] = useState({ backface: false, visual: false });

  // TODO clean this up and make a hook
  const ref = useRef(null);
  const handleClick = (ev: MouseEvent) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(ev.target)) {
      // @ts-ignore
      ref.current.style.transform = `translate(${ev.clientX}px, ${ev.clientY}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick, true);
    return () => {
      window.removeEventListener("click", handleClick, true);
      setView(true);
    };
  }, [selection]);


  const handleBackfaceClick = async () => {
    const node = selection[0].node;
    if (node && currentModel === "model") {
      setLoading({ ...loading, backface: true });
      await switchModelBackface(node, selectionProps.exchangeId, selectionProps.backface);

      setSelectionProps({ ...selectionProps, backface: !selectionProps.backface });
      setLoading({ ...loading, backface: false });
    } else {
      console.log("no-op on node - ", selection[0].node);
    }
  };

  const handleToggleVisualClick = async () => {
    const node = selection[0].node;
    if (node && (currentModel === "model" || visual)) {
      setLoading({ ...loading, visual: true });
      await switchModelVisualType(node);
      setLoading({ ...loading, visual: false });
      setSelectionProps({ ...selectionProps, visual: !selectionProps.visual });
      refetch();
    }
  };

  useEffect(() => {
    const id = selection[0];
    if (id?.node && (currentModel === "model" || visual)) {
      const viewer = getViewer();
      const parentId = getParentNodeName(id.node, viewer);
      const isVisual = isVisualFromNodeName(parentId);
      if (parentId !== null && isVisual !== null) {
        viewer.model.getNodeProperties(id.node)
          .then((p: any | null) => {
            if (p) {
              // @ts-ignore
              const update = {
                ...(p["H3DX_BRIDGE_Backface/H3DX_BRIDGE_Backface"] && { backface: p["H3DX_BRIDGE_Backface/H3DX_BRIDGE_Backface"] === "True" }),
                ...(p["H3DX_BRIDGE_ExchangeId/H3DX_BRIDGE_ExchangeId"] && { exchangeId: p["H3DX_BRIDGE_ExchangeId/H3DX_BRIDGE_ExchangeId"] }),
                visual: isVisual
              };
              setSelectionProps((v) => ({ ...v, ...update }));
            }
          });
      }
      else{
        console.log("couldn't find parent for node - ", id.node)
      }
    }
    return () => {
    };
  }, [selection, currentModel, getViewer, visual]);

  return (
    <div ref={ref} title={"hello"}>
      {view && selection.length > 0 && (currentModel === "model" || visual) ?
        (
          <div className="absolute bg-menuBackground p-2" onMouseLeave={() => setView(false)}>
            <div className="flex flex-col text-left text-lg">

              <p className="text-lg text-right border-b-2 pb-0.5 border-highPriority">Properties</p>

              {!selectionProps.visual ?
                <div className="pt-0.5 flex flex-row w-full items-center focus:outline-none">
                  {loading.backface ?
                    <div
                      className="animate-spin rounded-full h-2.5 w-2.5 border-b-2 border-highPriority"
                    /> :
                    <button
                      onClick={() => handleBackfaceClick()}
                      className={`${selectionProps.backface ? "bg-highlight" : null} border-2 border-highPriority h-2.5 w-2.5 rounded-full`}>
                    </button>}
                  <p className="text-base align-top px-2">panel backface</p>
                </div>
                :
                null}
              <div className="flex flex-row w-full items-center focus:outline-none">
                {loading.visual ?
                  <div
                    className="animate-spin rounded-full h-2.5 w-2.5 border-b-2 border-highPriority"
                  />
                  :
                  < button
                    onClick={() => handleToggleVisualClick()}
                    className={`${selectionProps.visual ? "bg-highlight" : null} border-2 border-highPriority h-2.5 w-2.5 rounded-full`} />
                }
                <p className="text-base align-top px-2">visual part</p>
              </div>
            </div>
          </div>
        )
        :
        null}
    </div>
  );
}
