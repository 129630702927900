// @flow
import * as React from "react";
import {  useMemo, useState } from "react";
import { DotToggleElem } from "../atoms/DotToggleElem";
import { SelectionElement, useStore } from "../../store/projectStore";

type BuildProps = {
  nodeId: number;
  level: number;
};
type BranchProps = {
  nodeId: number;
  // viewer: Communicator.WebViewer;
  level: number;
  // setParentSelected: (arg0: boolean)=> void;
};

const colorGradient = [
  "bg-puddy",
  "bg-clay",
  "bg-vapor",
  "bg-kinetic"
];

function Branch({ nodeId, level }: BranchProps) {
  const [expanded, setExpanded] = useState(true);
  // const [childSelected, setChildSelected] = useState(false);
  const viewer = useStore(store => store.getViewer());
  const toggleVisibility =useStore(store=>store.toggleVisibility);
  const selection = useStore(store=> store.selection)
  const currentModel = useStore(store=>store.currentModel)
  // calls back upwards looking for highlighted children
  // const highlightScan = (leaf: number) => {
  //
  // };
  const drillForSingleLayers = (children: number[]): number[] | null => {
    // if the'ere only one child try to remove it unless it's a leaf
    if (children.length === 1 && viewer) {
      const grandChildren = viewer.model.getNodeChildren(children[0]);
      if(!grandChildren[0]){
        // we've hit the leaf
        if(children.length === 1){
          // this is a straight branch with a single leaf - collapse it upward
          return null;
        }
        else{
          // display these children
          return children;
        }
      }else{
        // continue downward based on these children
        return drillForSingleLayers(grandChildren);
      }
    }
    return children;
  };

  const drillForSelection = (children: number[]): boolean => {
    // callbacks upwards were creating rendering issues... it would be a better solution
    const highlight = selection.some((v: SelectionElement) => children.some(k => k === v.node));
    if (highlight) {
      return true;
    }

    const grandChildren = viewer.model.getNodeChildren(children[0]);
    return !grandChildren[0] ? highlight : drillForSelection(grandChildren);
  };

  // I would like to find a way to memoize these
  const { children, name } = useMemo(() => {
    const children = drillForSingleLayers(viewer.model.getNodeChildren(nodeId));
    const name = viewer.model.getNodeName(nodeId);
    return { children, name };
  }, [nodeId, viewer, currentModel, drillForSingleLayers]);


  const { highlight } = useMemo(() => {
    const id = children ? children : [nodeId]
    const highlight = drillForSelection(id);
    return { highlight };
  }, [viewer, children, selection, nodeId, drillForSelection]);

  return (
    <>
      {children && children.length > 0  ? (
        <div className={`overflow-y-auto no-scrollbar inline-flex flex-col pl-${level}`}>
          <div className={`pl-${level}`}>
            <DotToggleElem fill={colorGradient[level % 4]}
                           content={name ? name : String(nodeId)}
                           active={viewer.model.getNodeVisibility(nodeId)}
                           onClick={() => setExpanded(!expanded)}
                           onDotClick={() => toggleVisibility(nodeId)}
                           secondary={highlight} />
          </div>
          {expanded ? children.map((child) => <Branch key={`Root${child}_${level + 1}`}
                                                      nodeId={child}
                                                      level={level + 1} />) : null}
        </div>
      ) : (
        <Leaf nodeId={nodeId} viewer={viewer} level={level} setParentSelected={()=>{}} />
      )}
    </>);
}

type LeafProps = {
  nodeId: number;
  viewer: Communicator.WebViewer;
  level: number;
  setParentSelected: (arg0: boolean) => void;
};

function Leaf({ nodeId, level, setParentSelected }: LeafProps) {
  const viewer = useStore(store => store.getViewer());
  const name = viewer?.model.getNodeName(nodeId);

  const toggleVisibility =useStore(store=>store.toggleVisibility);
  const selection = useStore(store=>store.selection)

  // useEffect(() => {
  //     const inSelection = selection?.some((v: SelectionElement) => v.node === nodeId);
  //     setHighlight(inSelection);
  //     if (inSelection) {
  //         console.log("setting parent")
  //         // callback to parent to highlight
  //         setParentSelected(inSelection);
  //     }
  //     return () => {};
  // }, [selection]);

  return (
    <div className={`pl-${level + 1}`}>
      <DotToggleElem content={name ? name : String(nodeId)} active={viewer?.model.getNodeVisibility(nodeId)}
                     onDotClick={() => toggleVisibility(nodeId)}
                     secondary={selection?.some((v: SelectionElement) => v.node === nodeId)} />
    </div>
  );
};

export const Root = ({ nodeId, level }: BuildProps) => {
  return (
    <>
      <Branch key={`branch${nodeId}`} nodeId={nodeId}
              level={level} />
    </>
  );
};

export default Root;
