import React from 'react';

type Props = {
    hover: boolean;
};

function FilesIcon({hover}: Props) {
    return (
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452.24 452">
            <path
                d="M39.93,39.79H330.28V66.74h39.25v-6c0-3.08.05-6.2.1-9.22.12-6.9.24-14-.26-21.08-.93-13.5-8.59-23.38-22.14-28.56-1.16-.45-2.29-.8-3.37-1.15L342.51.3l-.92-.3H29l-.67.16C9.89,4.5-.15,18.29,0,39c.33,43.84.27,88.43.22,131.56q0,24.56,0,49.12V252.8q0,41.18,0,82.36c0,19.28,14,33.7,33.23,34.29,2.32.08,4.64.1,6.95.1q2.49,0,4.92,0l5.17,0H66.89V329.81h-27Z"
                fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
            <path
                d="M452.24,117.13c0-14.52-6.57-25.28-19-31.12-5.95-2.79-12.56-3.39-17.06-3.4-86.25-.13-173.94-.12-258.75-.11H119.17a60.1,60.1,0,0,0-6.1.24C100.26,84.05,90.89,90.71,86,102a44.16,44.16,0,0,0-3.19,16.93c-.14,38.62-.13,77.89-.12,115.87q0,16.13,0,32.26v43q0,53.29,0,106.58C82.72,437.79,96.92,452,118,452H416.87c21.15,0,35.36-14.21,35.37-35.34V117.13Zm-39.7,5.07v290h-290v-290Z"
                               fill={hover ? '#B6EB1A' : '#E5E5E5'}
            />
            <polygon points="247.86 327.98 287.26 327.98 287.26 286.93 328.25 286.93 328.25 247.5 287.21 247.5 287.21 206.48 247.54 206.48 247.54 247.68 206.65 247.68 206.65 287.1 247.86 287.1 247.86 327.98"
                     fill={hover ? '#B6EB1A' : '#E5E5E5'}

            />

        </svg>
    );
}

export default FilesIcon;
