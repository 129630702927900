// @flow
import * as React from 'react';
import {useFormFields} from "../../helpers/hooks";
import LoginInput from "../atoms/LoginInput";
import {useState} from "react";
import { GetUserQuery, useUpdateUserMutation } from "../../generated/clientTypes";
import { signOut } from "../../helpers/useUser";
import { useNavigate } from "react-router-dom";

type Props = {
    showPopup: () => void;
    exit?: boolean;
    userInfo: GetUserQuery
    refetch: () => void
};


export function UserPopUp({userInfo, showPopup, refetch, exit = false}: Props) {
    const [message, setMessage] = useState(!userInfo ? "looks like you're new! we could use some details" : "");
    const history = useNavigate();
    const [fields, handleFieldChange] = useFormFields<{initials:string, company:string}>({
      initials: userInfo.getUser.initials, company: userInfo.getUser.company
    });
    const [updateUser] = useUpdateUserMutation({variables:{user:userInfo.getUser.user, ...fields}});

    const validateFields = () => {
        if (fields.initials === "" && fields.initials.length > 5 && fields.initials.length < 1) {
            setMessage("you'll need to set some initials");
            return false
        }
        if (fields.company === "" && fields.company.length < 2) {
            setMessage("you'll need to set a company, this is how you can share projects");
            return false
        }
        return true;
    }

    const handleUpdateUser = async  () => {
        if(validateFields()){
            await updateUser()
            refetch()
        }
    }

    const handleSignout = async ()=>{

        await signOut();
        history("/client")
    }

    return (
        <div className="h-screen w-screen">
            <div
                className="font-bold z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-auto bg-menuBackground text-highPriority">
                <div>
                    <div className="grid grid-cols-2 divide-x divide-highPriority px-6 py-3">
                        <div className="flex flex-col p-6 text-right">
                            <p className="my-1 px-1">username:</p>
                            <p className="my-1.5 px-1">email:</p>
                            <p className="my-1.5 px-1">company:</p>
                            <p className="my-1.5 px-1">initials:</p>
                        </div>
                        <div className="flex flex-col p-6">
                            <p className="my-1 px-1">{userInfo.getUser.user}</p>
                            <p className="my-1 px-1">{userInfo.getUser.email}</p>
                            <LoginInput labelName={"company"}
                                        type={"text"}
                                        value={fields.company}
                                        handleInputChange={handleFieldChange}
                                        className={"bg-menuBackground py-1 text-highPriority"}
                            />
                            <LoginInput labelName={"initials"}
                                        type={"text"}
                                        value={fields.initials}
                                        handleInputChange={handleFieldChange}
                                        className={"bg-menuBackground py-1 text-highPriority"}
                            />
                        </div>
                    </div>
                    {message !== "" ? <p className="px-6 pb-3">{message}</p> : null}
                </div>
                <div className="flex flex-col items-end">
                    <div className="p-6" onClick={()=>showPopup()}>
                        <p className="text-highPriority text-icon font-bold hover:text-highlight">X</p>
                    </div>
                    <div className="p-3">
                        <button
                            className="border-2 border-highPriority text-icon font-bold px-8 hover:text-highlight hover:border-highlight text-highPriority"
                            onClick={() => handleUpdateUser()}>
                            update info
                        </button>
                    </div>
                    <div className="p-3">
                        <button
                            className="border-2 border-highPriority text-icon font-bold px-8 hover:text-highlight hover:border-highlight text-highPriority"
                            onClick={() => handleSignout()}>
                            logout
                        </button>
                    </div>
                </div>
            </div>
            <div onClick={()=> showPopup()}
                 className="absolute top-0 left-0 z-0 h-screen w-screen bg-menuBackground opacity-50"/>
        </div>
    );
}
