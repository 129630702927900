import React from "react";

type Props = {
    year: number;
}

function Copywrite({year}: Props){
    return <p className="text-center text-highPriority text-base py-2">
        &copy;{year} Branch Technology. All rights reserved.
    </p>
}

export default Copywrite;

