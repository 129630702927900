// @flow
import * as React from 'react';

type Props = {
    text: string;
    onClick?: { (action: void): void };
    onTitleClick?: { (action: void): void };
    active?: boolean;
};

function MolHeader({text, active = false, onClick = () => {}, onTitleClick = () => {}}: Props) {
    return (
        <div className="w-full p-1">
            <div className="flex flex-auto items-center border-b-2 border-highPriority">
                <p className="w-full text-right text-title py-0.5 px-2" onClick={() => onTitleClick()}>
                    {text}
                </p>
            </div>
        </div>
    );
}

export default MolHeader;
