import React from "react";
import ModelerView from "./Modeler.view";
import { Canvas } from "../../hoops/components/Canvas";
import { useStore } from "../../store/projectStore";

function Modeler() {
  const {project,  user} = useStore(({user, project})=> ({user, project}));
  if (!project || !user) {
    return (
      <div className="w-screen max-w-screen h-screen max-h-screen bg-menuBackground flex justify-center items-center">
        {/*<div className="animate-spin rounded-full w-96 h-96 border-b-2 border-highPriority"/>*/}
      </div>
    )
  }
  return (
    <div className="relative h-screen w-screen bg-menuBackground">
      <div id="canvas" className="absolute h-screen w-screen" />
      <Canvas/>
      <ModelerView user={user} project={project}/>
    </div>
  );
}

export default Modeler;
