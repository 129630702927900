import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import { apolloClient } from '../App';
import {
  AnalysisTypes,
  AnalyzeDocument,
  AnalyzeNotification,
  ConvertDocument,
  ConvertNotification,
  FilesCreateDocument,
  FilesGetAllDocument,
  FilesGetAllQuery,
  FilesUpdateDocument,
  FileTypes,
  INotification,
  OnAnalyzeDocument,
  OnConvertDocument,
  OnPricingDocument,
  OnStatsDocument,
  PricingDocument,
  PricingNotification,
  S3Url,
  StatsDocument,
  StatsNotification,
  UpdateDocument,
} from '../generated/clientTypes';
import { uploadSignedUrl } from '../helpers/datasource';
import { ApolloQueryResult } from '@apollo/client';
import { cubicMToF, mToI, squareMToF } from '../helpers/conversion';
import { getSceneModel, parseKeyToModel } from './helper';
import {
  clearScene,
  createSceneModelKey,
  getKeyComponents,
  getParentNode,
  loadModelArray,
  setPers,
  setView,
  ToggleCubeToTriad,
  ToggleNodeVisibility,
  ViewCube,
} from '../helpers/ViewerEvents';
import { storage } from './indexdbStorage';
import HtmlId = Communicator.HtmlId;

export type EventStatus = '' | 'new' | 'success' | 'loading' | 'failure';

interface Convert extends ConvertNotification {
  status: EventStatus;
}

interface Analyze extends AnalyzeNotification {
  status: EventStatus;
  percentComplete: number;
}

interface Pricing extends PricingNotification {
  status: EventStatus;
  individualPrices: { [key: string]: PricingNotification };
}

interface Stats extends StatsNotification {
  status: EventStatus;
  individualStats: { [key: string]: StatsNotification };
}

export type Model = { id: number; type: FileTypes; key: string };

export interface SceneModel extends Model {
  nodeId: number;
}
export type SceneModels = { [key: string]: SceneModel };

export type SelectionElement = {
  node: number | null;
  face: number | undefined;
};

export type Selection = Array<SelectionElement> | [];

const tempError = (resp: any) => {
  console.log(resp);
  throw Error(resp);
};

const defaultNotification = {
  code: 400,
  id: 'new',
  user: '',
  project: '',
  createdAt: new Date().getTime(),
  status: 'new' as EventStatus,
};

const defaultState = {
  models: {},
  convert: null,
  curvature: null,
  depth: null,
  facet: null,
  undercut: null,
  shelf: null,
};

export type RootState = {
  user: string;
  project: string;
  axis: boolean;
  perspective: boolean;
  models: SceneModels;
  visualModels: SceneModels;
  currentModel: FileTypes | null;
  convert: Convert | null;
  curvature: Analyze | null;
  depth: Analyze | null;
  undercut: Analyze | null;
  shelf: Analyze | null;
  facet: Analyze | null;
  runAllAnalysis: (callback?: () => void) => Promise<void>;
  analyze: (type: AnalysisTypes, callback?: () => void, clear?: boolean) => Promise<void>;
  stats: Stats;
  viewer: Communicator.WebViewer;
  modelStructureReady: boolean;
  toggleVisibility: (nodeId: number) => Promise<void>;
  setViewer: (id: HTMLElement) => void;
  selection: Selection;
  sceneModels: SceneModels;
  addModelFromUrl: (s3Model: S3Url) => Promise<void>;
  addModelToScene: (sceneModel: Model, buffer: Uint8Array) => void;
  addModelsFromUrls: (s3ModelArray: Array<S3Url>, clear?: boolean, keepVisual?: boolean) => Promise<void>;
  toggleTriad: () => void;
  togglePerspective: () => void;
  getViewer: () => Communicator.WebViewer;
  setView: (view: keyof typeof Communicator.ViewOrientation) => void;
  clearScene: () => void;
  resetCamera: () => void;
  triggerStats: () => Promise<void>;
  pricing: Pricing;
  triggerPricing: () => Promise<void>;
  visual: boolean;
  colorBar: boolean;
  popModel: (id: string) => void;
  setColorBar: (state: boolean) => void;
  deleteModelType: (type: FileTypes) => void;
  setUser: (user: string) => void;
  setProject: (project: string) => void;
  setVisual: (state: boolean) => void;
  getSceneModels: (type: FileTypes, clear?: boolean, recenter?: boolean) => Promise<void>;
  getVisualSceneModels: () => Promise<void>;
  onConvert: (acceptedFile: any, callback: () => void) => Promise<void>;
  getDefaultNotification: () => INotification;
  makeModelVisual: (id: string, url?: string) => void;
  makeModelInteractive: (id: string, url?: string) => void;
  switchModelVisualType: (node: number) => Promise<void>;
  switchModelBackface: (node: number, exchangeId: string, state: boolean) => Promise<boolean>;
  clearModels: (keepVisual?: boolean) => Promise<void>;
  getSceneNodes: () => [];
  switchSceneModelUrl: (oldSceneModelId: string, url: any, oldKey?: boolean) => Promise<void>;
  toggleVisual: () => Promise<void>;
  invalidateVisualCache: boolean;
  invalidateModelCache: boolean;
};

// @ts-ignore
export const useStore = create<RootState, SetState<RootState>, GetState<RootState>, StoreApiWithPersist<RootState>>(persist(
    (set, get) => {
      // @ts-ignore
      let viewer: Communicator.WebViewer = undefined;
      return {
        user: '',
        project: '',
        models: {},
        visualModels: {},
        convert: { ...defaultNotification, type: 'convert' },
        curvature: { ...defaultNotification, type: 'analyze' },
        depth: { ...defaultNotification, type: 'analyze' },
        facet: { ...defaultNotification, type: 'analyze' },
        undercut: { ...defaultNotification, type: 'analyze' },
        shelf: { ...defaultNotification, type: 'analyze' },
        pricing: { ...defaultNotification, type: 'pricing' },
        stats: { ...defaultNotification, type: 'stats' },
        visual: false,
        currentModel: null,
        colorBar: true,
        modelStructureReady: false,
        selection: [],
        perspective: false,
        axis: false,
        invalidateVisualCache: false,
        getViewer: () => viewer,
        setViewer: async (containerId: HtmlId) => {
          viewer = new Communicator.WebViewer({
            containerId,
          });
          viewer.start();
          await new Promise<void>((resolve) => {
            viewer.setCallbacks({
              sceneReady: () => {
                viewer.view.setBackgroundColor(Communicator.Color.createFromFloatArray([0.121, 0.129, 0.149]), Communicator.Color.createFromFloatArray([0.929, 0.933, 0.952]));
                setPers(viewer, Communicator.Projection.Perspective);
                ViewCube(viewer);
              },
              modelStructureReady: () => {
                console.log('modelReady');
                const perspective = viewer.view.getProjectionMode() === Communicator.Projection.Perspective;
                const axis = viewer.view.getAxisTriad().getEnabled();
                set({ perspective, axis, modelStructureReady: true });

                resolve();
              },
              selectionArray: (_: any, a: boolean) => {
                // just take everything currently selected...
                const sel = viewer.selectionManager.getResults();
                const selection: Selection = sel.map((v) => ({
                  node: v.getNodeId(),
                  face: v.getFaceEntity()?.getCadFaceIndex(),
                }));
                set({ selection });
              },
            });
          });
        },
        togglePerspective: () => {
          const curView =
            viewer && viewer.view.getProjectionMode() === Communicator.Projection.Orthographic ? Communicator.Projection.Perspective : Communicator.Projection.Orthographic;
          setPers(viewer, curView);
          set({ perspective: viewer.view.getProjectionMode() === Communicator.Projection.Perspective });
        },
        toggleTriad: () => {
          ToggleCubeToTriad(viewer);
          set({ axis: viewer.view.getAxisTriad().getEnabled() });
        },
        setView: (view: keyof typeof Communicator.ViewOrientation) => {
          setView(viewer, view);
        },
        clearScene: async () => {
          if (viewer) {
            await clearScene(viewer, -2);
          }
        },
        resetCamera: async () => {
          await viewer.view.resetCamera();
        },
        toggleVisual: async () => {
          const { visual, getVisualSceneModels, deleteModelType, setVisual } = get();
          if (!visual) {
            await getVisualSceneModels();
          } else {
            deleteModelType('visual');
            setVisual(false);
          }
        },
        setVisual: (state: boolean) => {
          set({ visual: state });
        },
        setColorBar: (state: boolean) => set({ colorBar: state }),
        onConvert: async (acceptedFile: any, callback: () => void) => {
          const defNotification = get().getDefaultNotification();
          const { user, project, clearModels } = get();
          // set state + fileType to converting
          await clearModels(true);
          // set state + fileType to convertin
          const convert: Convert = {
            ...defNotification,
            type: 'convert',
            status: 'loading',
          };
          set({ currentModel: 'model', convert });
          // upload file
          const variables = { user, project, type: 'input', ext: 'stp' };
          const url = await apolloClient.mutate({ mutation: FilesCreateDocument, variables }).catch(tempError);
          await uploadSignedUrl(url.data.filesCreate.url, acceptedFile);
          // catch in case of error
          // setup listener to conversion notification
          const subscription = await apolloClient.subscribe({ query: OnConvertDocument, variables: { user, project } });
          // if successful callback: updates files arr to new signed urls + current scene filetype

          subscription.subscribe({
            next: async (resp: any) => {
              const convertNotification = resp.data.onConvert as ConvertNotification;
              console.log(convertNotification);
              if (convertNotification.code === 200) {
                await get().getSceneModels('model');
                set({
                  convert: { ...convertNotification, status: 'success' },
                });
                callback();
              } else {
                set({ convert: { ...convert, status: 'failure' } });
              }
            },
          });

          // trigger conversion
          await apolloClient
            .mutate({
              mutation: ConvertDocument,
              variables,
            })
            .catch(tempError);

          // TODO figure out if we need to unsubscribe and what kind of hit we take
        },
        runAllAnalysis: async (callback: () => void) => {
          const analysisTypes: AnalysisTypes[] = ['facet', 'depth', 'undercut', 'shelf', 'curvature'];
          const { analyze, clearModels } = get();
          await clearModels(true);

          const finishedMutex = [];
          const finishUpdate = () => {
            finishedMutex.push('done');
            if (finishedMutex.length === analysisTypes.length) {
              callback();
            }
          };
          analysisTypes.forEach((v) => {
            analyze(v, finishUpdate, false);
          });
        },
        analyze: async (analysisType: AnalysisTypes, callback = () => {}, clear = true) => {
          const { user, project, clearModels } = get();
          // @ts-ignore
          set({
            currentModel: analysisType,
            [analysisType]: {
              ...defaultNotification,
              type: 'analyze',
              analysisType,
              status: 'loading',
            },
          });
          if (clear) {
            await clearModels(true);
          }

          // catch in case of error
          // setup listener to conversion notification
          const subscription = apolloClient.subscribe({
            query: OnAnalyzeDocument,
            variables: { user, project, type: analysisType },
          });

          const addModelFromUrl = get().addModelFromUrl;
          let analysisPartLength = 0;
          let currentCount = 0;
          // if successful callback: updates files arr to new signed urls + current scene filetype
          subscription.subscribe({
            next: (resp: any) => {
              const analyzeNotification = resp.data.onAnalyze;
              if (analyzeNotification.code === 200 && analyzeNotification.type === 'analyzeUpdate' && analyzeNotification.model) {
                if (analyzeNotification.analysisType === get().currentModel) {
                  addModelFromUrl(analyzeNotification.model);
                }
                // @ts-ignore
                const { [analysisType]: curAnalysis } = get();
                currentCount++;
                // @ts-ignore
                set({
                  [analysisType]: {
                    ...curAnalysis,
                    percentComplete: Math.round((currentCount / analysisPartLength) * 100),
                  },
                });
              } else if (analyzeNotification.code === 200 && analyzeNotification.type === 'analyze') {
                callback();
                // @ts-ignore
                set({ [analysisType]: { ...analyzeNotification, status: 'success' } });
              } else {
                // @ts-ignore
                set({ [analysisType]: { ...analyzeNotification, status: 'failure' } });
              }
            },
          });

          // trigger conversion
          const resp = await apolloClient.mutate({
            mutation: AnalyzeDocument,
            variables: {
              user,
              project,
              type: analysisType as AnalysisTypes,
            },
          });
          if (resp.data.analyze.ids.length) {
            analysisPartLength = resp.data.analyze.ids.length;
          } else {
            console.log('failed to initialize analysis');
          }
        },
        triggerStats: async () => {
          const user = get().user;
          const project = get().project;
          const defNotification = get().getDefaultNotification();

          // set state + fileType to converting
          const stats: Stats = {
            ...defNotification,
            type: 'stats',
            status: 'loading',
            individualStats: {},
          };
          set({ stats });

          const formatStats = (stats: StatsNotification) => {
            return stats.stats
              ? {
                  ...stats,
                  stats: {
                    ...stats.stats,
                    averageThickness: mToI(stats.stats.averageThickness),
                    backFaceArea: squareMToF(stats.stats.backFaceArea),
                    featureFaceArea: squareMToF(stats.stats.featureFaceArea),
                    volume: cubicMToF(stats.stats.volume),
                  },
                }
              : stats;
          };
          // catch in case of error
          // setup listener to conversion notification
          const subscription = await apolloClient.subscribe({
            query: OnStatsDocument,
            variables: { user, project },
          });
          // if successful callback: updates files arr to new signed urls + current scene filetype
          subscription.subscribe({
            next: (resp: any) => {
              const statsNotification = resp.data.onStats as StatsNotification;
              if (statsNotification.code === 200 && statsNotification.type === 'statsUpdate' && statsNotification.stats && statsNotification.partId) {
                set({
                  stats: {
                    ...stats,
                    individualStats: { [statsNotification.partId as string]: formatStats(statsNotification) },
                  },
                });
              } else if (statsNotification.code === 200 && statsNotification.type === 'stats' && statsNotification.stats) {
                console.log('finished stats');
                set({ stats: { ...stats, ...formatStats(statsNotification), status: 'success' } });
              } else {
                set({ stats: { ...statsNotification, status: 'failure', individualStats: {} } });
              }
            },
          });

          await apolloClient.mutate({
            mutation: StatsDocument,
            variables: { user, project },
          });
        },
        triggerPricing: async () => {
          const user = get().user;
          const project = get().project;
          const defNotification = get().getDefaultNotification();

          // set state + fileType to converting
          const pricing: Pricing = {
            ...defNotification,
            type: 'pricing',
            status: 'loading',
            individualPrices: {},
          };
          set({ pricing });

          const subscription = await apolloClient.subscribe({
            query: OnPricingDocument,
            variables: { user, project },
          });
          // if successful callback: updates files arr to new signed urls + current scene filetype
          subscription.subscribe({
            next: (resp: any) => {
              const pricingNotification = resp.data.onPricing as PricingNotification;
              if (pricingNotification.code === 200 && pricingNotification.type === 'pricingUpdate' && pricingNotification.price && pricingNotification.partId) {
                set({
                  pricing: {
                    ...pricing,
                    individualPrices: { [pricingNotification.partId as string]: pricingNotification },
                  },
                });
              } else if (pricingNotification.code === 200 && pricingNotification.type === 'pricing' && pricingNotification.price) {
                set({ pricing: { ...pricing, ...pricingNotification, status: 'success' } });
              } else {
                set({ pricing: { ...pricingNotification, status: 'failure', individualPrices: {} } });
              }
            },
          });
          // trigger conversion
          await apolloClient.mutate({
            mutation: PricingDocument,
            variables: {
              user,
              project,
            },
          });
        },
        getDefaultNotification: () => {
          return {
            code: 400,
            id: 'new',
            user: get().user,
            project: get().project,
            createdAt: new Date().getTime(),
          };
        },
        getVisualSceneModels: async () => {
          const { user, project, invalidateVisualCache } = get();
          const files: ApolloQueryResult<FilesGetAllQuery | undefined> = await apolloClient.query({
            query: FilesGetAllDocument,
            variables: { user, project, filter: { ext: { contains: 'scs' }, type: { contains: 'visual' } } },
            fetchPolicy: invalidateVisualCache ? 'network-only' : 'cache-first',
          });
          if (invalidateVisualCache) {
            await get().getSceneModels('model', false);
          }
          if (files && files.data && files.data.filesGetAll.length > 0) {
            await get().addModelsFromUrls(files.data.filesGetAll, false);
            set({ visual: true, invalidateVisualCache: false });
          }
        },
        getSceneModels: async (type: FileTypes, clear = true, recenter = false) => {
          const { user, project, invalidateModelCache } = get();
          const files: ApolloQueryResult<FilesGetAllQuery | undefined> = await apolloClient.query({
            query: FilesGetAllDocument,
            variables: { user, project, filter: { ext: { contains: 'scs' }, type: { contains: type } } },
            fetchPolicy: invalidateModelCache && type === 'model' ? 'network-only' : 'cache-first',
          });
          if (files && files.data && files.data.filesGetAll) {
            await get().addModelsFromUrls(files.data.filesGetAll, clear);
            if (recenter) {
              get().setView('Front');
            }
            set({ currentModel: type, invalidateModelCache: false });
          }
        },
        setUser: (user: string) => set({ user }),
        setProject: (project: string) => {
          const { project: prevProject, clearScene } = get();
          if (project !== prevProject) {
            clearScene();
          }
          set({ project, currentModel: null, visual: false, ...defaultState });
        },
        addModelsFromUrls: async (s3ModelArray: Array<S3Url>, clear = true, keepVisual = true) => {
          const { clearModels, addModelFromUrl } = get();
          if (clear) {
            await clearModels(keepVisual);
          }
          const resp = s3ModelArray.map(async (v) => await addModelFromUrl(v));
          await Promise.all(resp);
        },
        toggleVisibility: async (nodeId: number) => {
          await ToggleNodeVisibility(viewer, nodeId);
        },
        makeModelInteractive: (key: string) => {
          const { [key]: modelToMakeInteractive, ...models } = get().models;
          modelToMakeInteractive.type = 'model';
          const { id } = getKeyComponents(key);
          const newKey = createSceneModelKey({ id, type: 'model' });
          set({ models: { ...models, [newKey]: modelToMakeInteractive } });
        },
        makeModelVisual: (id: string) => {
          const { [id]: modelToMakeVisual, ...models } = get().models;
          modelToMakeVisual.type = 'visual';
          const newKey = createSceneModelKey(modelToMakeVisual);
          // const visualModels = { ...get().visualModels, [newKey]: modelToMakeVisual };
          set({ models: { ...models, [newKey]: modelToMakeVisual } });
        },
        popModel: (model: { id: number; type: string } | string) => {
          const newKey = typeof model !== 'string' ? createSceneModelKey(model) : model;
          const { [newKey]: _, ...models } = get().models;
          set({ models });
        },
        switchSceneModelUrl: async (sceneModelId: string, url: S3Url, oldKey: boolean = false) => {
          const { visual, models, addModelFromUrl, popModel } = get();
          if (models.hasOwnProperty(sceneModelId)) {
            const model = models[sceneModelId];
            await viewer.model.deleteNode(model.nodeId);
            popModel(sceneModelId);
            const val = oldKey ? { key: model.key, url } : url;
            const { type } = parseKeyToModel(val.key);
            if (!visual && type === 'visual') {
              console.log('skipping');
            } else {
              await addModelFromUrl(val);
            }
          } else {
            console.log("couldn't find model in store");
          }
        },
        deleteModelType: async (type: FileTypes) => {
          type LocalReduce = { promises: Array<Promise<void>>; remaining: SceneModels };
          const { models } = get();

          const { promises, remaining } = Object.entries(models).reduce<LocalReduce>(
            (a, [k, v]) => {
              v.type === type ? a.promises.push(viewer.model.deleteNode(v.nodeId)) : (a.remaining[k] = v);
              return a;
            },
            { promises: [], remaining: {} }
          );

          await Promise.all(promises);

          set({ models: remaining });
        },
        clearModels: async (keepVisual: boolean) => {
          const { models, visual } = get();
          type KeepVisual = {
            deletePromises: Array<Promise<void>>;
            newModels: SceneModels;
          };
          if (keepVisual && visual) {
            const { deletePromises, newModels } = Object.entries(models).reduce<KeepVisual>(
              ({ deletePromises, newModels }, [k, v]) => {
                if (v.type === 'visual') {
                  // skip
                  return { deletePromises, newModels: { ...newModels, [k]: v } };
                } else {
                  return { deletePromises: [...deletePromises, viewer.model.deleteNode(v.nodeId)], newModels };
                }
              },
              { deletePromises: [], newModels: {} }
            );
            set({ models: { ...newModels } });
            return await Promise.all(deletePromises);
          } else {
            // resorting to crawling over tree as there are times where it can get out of sync.
            await clearScene(viewer, -2);
            set({ models: {} });
            return;
          }
        },
        getSceneNodes: () => {
          return viewer.model.getNodeChildren(-2);
        },
        addModelFromUrl: async (s3Model: S3Url) => {
          const { model, modelArray } = await getSceneModel(s3Model);
          await get().addModelToScene(model, modelArray);
        },
        addModelToScene: async (model: Model, modelArray: Uint8Array) => {
          const { models } = get();
          const { nodeName, nodeId } = await loadModelArray({ viewer, model, modelArray });
          models[nodeName] = { nodeId, ...model };
          set({ models });
        },
        popSceneModel: (nodeId: number) => {
          viewer?.model.deleteNode(nodeId);
        },
        switchModelBackface: async (node: number, exchangeId: string, state: boolean) => {
          const { models, user, project, switchSceneModelUrl } = get();
          const parentNode = getParentNode(node, viewer);
          if (parentNode) {
            const parentId = viewer.model.getNodeName(parentNode);
            if (parentId && models.hasOwnProperty(parentId)) {
              const model = models[parentId];
              const resp = await apolloClient.mutate({
                mutation: UpdateDocument,
                variables: {
                  user,
                  project,
                  type: 'model',
                  id: model.id,
                  exchangeId,
                  state: !state,
                },
              });
              console.log(resp.data.update.body);
              await switchSceneModelUrl(parentId, resp.data.update.body, true);
              set({ invalidateModelCache: true });

              return resp.data?.update;
            } else {
              return false;
            }
          } else {
            return false;
          }
        },
        switchModelVisualType: async (node: number) => {
          const { user, project, models } = get();
          const parentNode = getParentNode(node, viewer);
          if (parentNode) {
            const parentId = viewer.model.getNodeName(parentNode);
            if (parentId && models.hasOwnProperty(parentId)) {
              const { id, type } = models[parentId];
              if (type === 'model' || type === 'visual') {
                const newType = type === 'model' ? 'visual' : 'model';
                const prc = apolloClient.mutate({
                  mutation: FilesUpdateDocument,
                  variables: {
                    input: [
                      {
                        reference: { user, project, id, type, ext: 'prc' },
                        type: newType,
                      },
                    ],
                  },
                });
                const resp = await apolloClient.mutate({
                  mutation: FilesUpdateDocument,
                  variables: {
                    input: [
                      {
                        reference: { user, project, id, type, ext: 'scs' },
                        type: newType,
                      },
                    ],
                  },
                });
                await prc;
                if (resp.data && resp.data.filesUpdate[0]) {
                  console.log(resp.data.filesUpdate[0]);

                  function timeout(ms: number) {
                    return new Promise((resolve) => setTimeout(resolve, ms));
                  }

                  await timeout(1000);
                  await get().switchSceneModelUrl(parentId, resp.data.filesUpdate[0]);
                  // if (!parentIsVisual) {
                  //   if (get().visual) {
                  //     get().makeModelVisual(parentNodeName);
                  //   } else {
                  //     get().popModel(parentNodeName);
                  //     viewer?.model.deleteNode(parentId);
                  //   }
                  // } else {
                  //   get().makeModelInteractive(parentNodeName);
                  // const {id} = getKeyComponents(parentNodeName);
                  // const newName = createSceneModelKey({id, type: "model"})
                  // const valid = viewer.model.addPropertyToNode(parentId, "name", newName);
                  // const props = await viewer.model.get(parentId)
                  // console.log(props)
                  // await viewer.model.deleteNode(parentId);
                  // viewer.model.createNode(-2, newName, null, )
                  // const test = viewer.model.getNodeName(parentId)
                  // console.log(test)
                  // }
                  set({ invalidateVisualCache: true, invalidateModelCache: true });
                }
              }
            }
          } else {
            console.log("couldn't find parent node");
          }
        },
      };
    },
    {
      name: 'user-store', // unique name
      getStorage: () => storage,
    }
  )
);
