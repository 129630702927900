import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
};

export type AnalysisTypes = 'curvature' | 'depth' | 'facet' | 'shelf' | 'undercut';

export type AnalyzeInput = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  project: Scalars['String'];
  type: AnalysisTypes;
  user: Scalars['String'];
};

export type AnalyzeNotification = INotification & {
  __typename?: 'AnalyzeNotification';
  analysisType: AnalysisTypes;
  code: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  model?: Maybe<S3Url>;
  partId?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type ComputeLambda = 'analyze' | 'convert' | 'pricing' | 'stats';

export type ConvertMutationMessage = {
  message?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<S3UrlInput>>>;
};

export type ConvertNotification = INotification & {
  __typename?: 'ConvertNotification';
  code: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  models?: Maybe<Array<S3Url>>;
  partId?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type CreateCognitoUser = {
  affiliate?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateProject = {
  archive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  lastEditedBy?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  user: Scalars['String'];
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['Int']>;
};

export type CreateUser = {
  affiliate?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  eula?: Maybe<Scalars['Int']>;
  initials?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type DeleteProject = {
  project: Scalars['String'];
  user: Scalars['String'];
};

export type DeleteUser = {
  user: Scalars['String'];
};

export type Extensions = 'prc' | 'scs' | 'stp';

export type File = {
  ext: Extensions;
  id?: Maybe<Scalars['Int']>;
  project: Scalars['String'];
  type: FileTypes;
  user: Scalars['String'];
};

export type FileComponentFilter = {
  ext?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['Boolean']>;
};

export type FileTypes = 'curvature' | 'depth' | 'facet' | 'input' | 'model' | 'output' | 'shelf' | 'undercut' | 'visual';

export type FilesBooleanFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type FilesFilterInput = {
  contains?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
};

export type FilesGetAllFilterInput = {
  ext?: Maybe<FilesStringFilterInput>;
  type?: Maybe<FilesStringFilterInput>;
  unique?: Maybe<FilesBooleanFilterInput>;
};

export type FilesIntFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
};

export type FilesListAllFilterInput = {
  fileComponents?: Maybe<FileComponentFilter>;
  type?: Maybe<FilesStringFilterInput>;
  unique?: Maybe<FilesBooleanFilterInput>;
};

export type FilesStringFilterInput = {
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
};

export type MapStepFunctionResponse = {
  __typename?: 'MapStepFunctionResponse';
  code: Scalars['Int'];
  fileType?: Maybe<FileTypes>;
  ids: Array<Maybe<Scalars['Int']>>;
  message: Scalars['String'];
  type: ComputeLambda;
};

export type Mutation = {
  __typename?: 'Mutation';
  analyze?: Maybe<MapStepFunctionResponse>;
  analyzeNotification?: Maybe<AnalyzeNotification>;
  analyzeUpdate?: Maybe<AnalyzeNotification>;
  convert?: Maybe<StepFunctionResp>;
  convertNotification: ConvertNotification;
  createProject: Project;
  createUser: User;
  deleteProject: Project;
  deleteUser: User;
  filesCreate: S3Url;
  filesUpdate: Array<S3Url>;
  pricing?: Maybe<MapStepFunctionResponse>;
  pricingNotification?: Maybe<PricingNotification>;
  pricingUpdate?: Maybe<PricingNotification>;
  stats?: Maybe<MapStepFunctionResponse>;
  statsNotification?: Maybe<StatsNotification>;
  statsUpdate?: Maybe<StatsNotification>;
  update: UpdateResponse;
  updateProject: Project;
  updateUser: User;
};

export type MutationAnalyzeArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  project: Scalars['String'];
  type: AnalysisTypes;
  user: Scalars['String'];
};

export type MutationAnalyzeNotificationArgs = {
  analysisType: AnalysisTypes;
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationAnalyzeUpdateArgs = {
  analysisType: AnalysisTypes;
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  model?: Maybe<S3UrlInput>;
  partId: Scalars['String'];
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationConvertArgs = {
  project: Scalars['String'];
  user: Scalars['String'];
};

export type MutationConvertNotificationArgs = {
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<S3UrlInput>>>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationCreateProjectArgs = {
  input: CreateProject;
};

export type MutationCreateUserArgs = {
  input: CreateUser;
};

export type MutationDeleteProjectArgs = {
  input: DeleteProject;
};

export type MutationDeleteUserArgs = {
  input: DeleteUser;
};

export type MutationFilesCreateArgs = {
  ext?: Maybe<Extensions>;
  id?: Maybe<Scalars['Int']>;
  project: Scalars['String'];
  type: FileTypes;
  user: Scalars['String'];
};

export type MutationFilesUpdateArgs = {
  input: Array<FilesUpdateInput>;
};

export type MutationPricingArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  project: Scalars['String'];
  user: Scalars['String'];
};

export type MutationPricingNotificationArgs = {
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationPricingUpdateArgs = {
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  partId: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationStatsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  project: Scalars['String'];
  user: Scalars['String'];
};

export type MutationStatsNotificationArgs = {
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  stats?: Maybe<StatsInput>;
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationStatsUpdateArgs = {
  code: Scalars['Int'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  partId: Scalars['String'];
  project: Scalars['String'];
  stats?: Maybe<StatsInput>;
  type: NotificationType;
  user: Scalars['String'];
};

export type MutationUpdateArgs = {
  exchangeId: Scalars['String'];
  id: Scalars['Int'];
  project: Scalars['String'];
  state: Scalars['Boolean'];
  type: Scalars['String'];
  user: Scalars['String'];
};

export type MutationUpdateProjectArgs = {
  input: UpdateProject;
};

export type MutationUpdateUserArgs = {
  input: UpdateUser;
};

export type NotificationType = 'analyze' | 'analyzeUpdate' | 'convert' | 'pricing' | 'pricingUpdate' | 'stats' | 'statsUpdate';

export type PricingInput = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  project: Scalars['String'];
  user: Scalars['String'];
};

export type PricingNotification = INotification & {
  __typename?: 'PricingNotification';
  code: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  partId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  archive: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  createdBy: Scalars['String'];
  lastEditedBy: Scalars['String'];
  project: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user: Scalars['String'];
  users: Array<Maybe<Scalars['String']>>;
  version: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  filesGetAll: Array<S3Url>;
  filesListAll: Array<Maybe<Scalars['String']>>;
  getFile: Scalars['String'];
  getProject: Project;
  getUser: User;
  listProject: Array<Maybe<Project>>;
  listUser: Array<Maybe<User>>;
};

export type QueryFilesGetAllArgs = {
  filter?: Maybe<FilesGetAllFilterInput>;
  project: Scalars['String'];
  user: Scalars['String'];
};

export type QueryFilesListAllArgs = {
  filter?: Maybe<FilesListAllFilterInput>;
  project: Scalars['String'];
  user: Scalars['String'];
};

export type QueryGetFileArgs = {
  key: Scalars['String'];
};

export type QueryGetProjectArgs = {
  project: Scalars['String'];
  user: Scalars['String'];
};

export type QueryGetUserArgs = {
  user: Scalars['String'];
};

export type QueryListProjectArgs = {
  user: Scalars['String'];
};

export type QueryListUserArgs = {
  filter?: Maybe<TableUserFilterInput>;
};

export type S3Url = {
  __typename?: 'S3Url';
  key: Scalars['String'];
  url: Scalars['AWSURL'];
};

export type S3UrlInput = {
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['AWSURL']>;
};

export type Stats = {
  __typename?: 'Stats';
  area: Scalars['Float'];
  averageThickness: Scalars['Float'];
  backFaceArea: Scalars['Float'];
  featureFaceArea: Scalars['Float'];
  featureFaceAreaToBackFaceAreaRatio: Scalars['Float'];
  maximumThickness: Scalars['Float'];
  minimumThickness: Scalars['Float'];
  volume: Scalars['Float'];
};

export type StatsInput = {
  area: Scalars['Float'];
  averageThickness: Scalars['Float'];
  backFaceArea: Scalars['Float'];
  featureFaceArea: Scalars['Float'];
  featureFaceAreaToBackFaceAreaRatio: Scalars['Float'];
  maximumThickness: Scalars['Float'];
  minimumThickness: Scalars['Float'];
  volume: Scalars['Float'];
};

export type StatsNotification = INotification & {
  __typename?: 'StatsNotification';
  code: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  partId?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  stats?: Maybe<Stats>;
  type: NotificationType;
  user: Scalars['String'];
};

export type StepFunctionResp = {
  __typename?: 'StepFunctionResp';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onAnalyze?: Maybe<AnalyzeNotification>;
  onConvert?: Maybe<ConvertNotification>;
  onPricing?: Maybe<PricingNotification>;
  onStats?: Maybe<StatsNotification>;
};

export type SubscriptionOnAnalyzeArgs = {
  analysisType?: Maybe<AnalysisTypes>;
  project?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type SubscriptionOnConvertArgs = {
  project?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type SubscriptionOnPricingArgs = {
  project?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type SubscriptionOnStatsArgs = {
  project?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type TableBooleanFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
};

export type TableFloatFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  contains?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
};

export type TableIdFilterInput = {
  beginsWith?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
};

export type TableProjectFilterInput = {
  archive?: Maybe<TableBooleanFilterInput>;
  createdAt?: Maybe<TableStringFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  lastEditedBy?: Maybe<TableStringFilterInput>;
  project?: Maybe<TableStringFilterInput>;
  updatedAt?: Maybe<TableStringFilterInput>;
  user?: Maybe<TableStringFilterInput>;
  users?: Maybe<TableStringFilterInput>;
  version?: Maybe<TableIntFilterInput>;
};

export type TableStringFilterInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type TableUserFilterInput = {
  affiliate?: Maybe<TableBooleanFilterInput>;
  email?: Maybe<TableStringFilterInput>;
  state?: Maybe<TableStringFilterInput>;
  user?: Maybe<TableStringFilterInput>;
};

export type UpdateProject = {
  archive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  lastEditedBy?: Maybe<Scalars['String']>;
  project: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  user: Scalars['String'];
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  body: Scalars['String'];
  functionName: Scalars['String'];
  message: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateUser = {
  affiliate?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eula?: Maybe<Scalars['Int']>;
  initials?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  affiliate: Scalars['Boolean'];
  company: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['String'];
  eula: Scalars['Int'];
  initials: Scalars['String'];
  privacy: Scalars['Int'];
  state: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  user: Scalars['String'];
};

export type Convert = {
  __typename?: 'convert';
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
};

export type FilesUpdateInput = {
  ext?: Maybe<Extensions>;
  id?: Maybe<Scalars['Int']>;
  project?: Maybe<Scalars['String']>;
  reference: File;
  type?: Maybe<FileTypes>;
  user?: Maybe<Scalars['String']>;
};

export type INotification = {
  code: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['String'];
  project: Scalars['String'];
  type: NotificationType;
  user: Scalars['String'];
};

export type FilesListAllQueryVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  filter?: Maybe<FilesListAllFilterInput>;
}>;

export type FilesListAllQuery = { __typename?: 'Query'; filesListAll: Array<string | null | undefined> };

export type FilesUpdateMutationVariables = Exact<{
  input: Array<FilesUpdateInput> | FilesUpdateInput;
}>;

export type FilesUpdateMutation = { __typename?: 'Mutation'; filesUpdate: Array<{ __typename?: 'S3Url'; url: any; key: string }> };

export type FilesGetAllQueryVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  filter?: Maybe<FilesGetAllFilterInput>;
}>;

export type FilesGetAllQuery = { __typename?: 'Query'; filesGetAll: Array<{ __typename?: 'S3Url'; url: any; key: string }> };

export type FilesCreateMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  type: FileTypes;
  id?: Maybe<Scalars['Int']>;
  ext?: Maybe<Extensions>;
}>;

export type FilesCreateMutation = { __typename?: 'Mutation'; filesCreate: { __typename?: 'S3Url'; url: any; key: string } };

export type ConvertMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
}>;

export type ConvertMutation = { __typename?: 'Mutation'; convert?: { __typename?: 'StepFunctionResp'; message: string; code: number } | null | undefined };

export type UpdateMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  type: Scalars['String'];
  id: Scalars['Int'];
  exchangeId: Scalars['String'];
  state: Scalars['Boolean'];
}>;

export type UpdateMutation = { __typename?: 'Mutation'; update: { __typename?: 'UpdateResponse'; status: string; message: string; functionName: string; body: string } };

export type StatsMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  ids?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type StatsMutation = {
  __typename?: 'Mutation';
  stats?:
    | { __typename?: 'MapStepFunctionResponse'; message: string; code: number; fileType?: FileTypes | null | undefined; ids: Array<number | null | undefined> }
    | null
    | undefined;
};

export type PricingMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  ids?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type PricingMutation = {
  __typename?: 'Mutation';
  pricing?:
    | { __typename?: 'MapStepFunctionResponse'; message: string; code: number; fileType?: FileTypes | null | undefined; ids: Array<number | null | undefined> }
    | null
    | undefined;
};

export type AnalyzeMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  type: AnalysisTypes;
  ids?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type AnalyzeMutation = {
  __typename?: 'Mutation';
  analyze?:
    | { __typename?: 'MapStepFunctionResponse'; message: string; code: number; type: ComputeLambda; fileType?: FileTypes | null | undefined; ids: Array<number | null | undefined> }
    | null
    | undefined;
};

export type AnalyzeNotificationMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type: NotificationType;
  analysisType: AnalysisTypes;
}>;

export type AnalyzeNotificationMutation = {
  __typename?: 'Mutation';
  analyzeNotification?:
    | {
        __typename?: 'AnalyzeNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        createdAt: any;
        code: number;
        analysisType: AnalysisTypes;
      }
    | null
    | undefined;
};

export type AnalyzeUpdateMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  partId: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  model?: Maybe<S3UrlInput>;
  message?: Maybe<Scalars['String']>;
  type: NotificationType;
  analysisType: AnalysisTypes;
}>;

export type AnalyzeUpdateMutation = {
  __typename?: 'Mutation';
  analyzeUpdate?:
    | {
        __typename?: 'AnalyzeNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        partId?: string | null | undefined;
        createdAt: any;
        code: number;
        analysisType: AnalysisTypes;
        model?: { __typename?: 'S3Url'; key: string; url: any } | null | undefined;
      }
    | null
    | undefined;
};

export type StatsUpdateMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  partId: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type: NotificationType;
  stats: StatsInput;
}>;

export type StatsUpdateMutation = {
  __typename?: 'Mutation';
  statsUpdate?:
    | {
        __typename?: 'StatsNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        partId?: string | null | undefined;
        createdAt: any;
        code: number;
        stats?:
          | {
              __typename?: 'Stats';
              volume: number;
              area: number;
              backFaceArea: number;
              featureFaceArea: number;
              averageThickness: number;
              minimumThickness: number;
              maximumThickness: number;
              featureFaceAreaToBackFaceAreaRatio: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type StatsNotificationMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type: NotificationType;
  stats: StatsInput;
}>;

export type StatsNotificationMutation = {
  __typename?: 'Mutation';
  statsNotification?:
    | {
        __typename?: 'StatsNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        createdAt: any;
        code: number;
        stats?:
          | {
              __typename?: 'Stats';
              volume: number;
              area: number;
              backFaceArea: number;
              featureFaceArea: number;
              averageThickness: number;
              minimumThickness: number;
              maximumThickness: number;
              featureFaceAreaToBackFaceAreaRatio: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PricingUpdateMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  partId: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  type: NotificationType;
}>;

export type PricingUpdateMutation = {
  __typename?: 'Mutation';
  pricingUpdate?:
    | {
        __typename?: 'PricingNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        partId?: string | null | undefined;
        createdAt: any;
        code: number;
        price?: number | null | undefined;
      }
    | null
    | undefined;
};

export type PricingNotificationMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  type: NotificationType;
}>;

export type PricingNotificationMutation = {
  __typename?: 'Mutation';
  pricingNotification?:
    | {
        __typename?: 'PricingNotification';
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        createdAt: any;
        code: number;
        price?: number | null | undefined;
      }
    | null
    | undefined;
};

export type ConvertNotificationMutationVariables = Exact<{
  code: Scalars['Int'];
  id: Scalars['String'];
  project: Scalars['String'];
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<S3UrlInput>> | Maybe<S3UrlInput>>;
  type: NotificationType;
}>;

export type ConvertNotificationMutation = {
  __typename?: 'Mutation';
  convertNotification: {
    __typename?: 'ConvertNotification';
    user: string;
    project: string;
    type: NotificationType;
    message?: string | null | undefined;
    id: string;
    createdAt: any;
    code: number;
    models?: Array<{ __typename?: 'S3Url'; key: string; url: any }> | null | undefined;
  };
};

export type CreateProjectMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'Project';
    updatedAt: any;
    user: string;
    version: number;
    users: Array<string | null | undefined>;
    project: string;
    lastEditedBy: string;
    createdAt: any;
    createdBy: string;
    archive: boolean;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  archive?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lastEditedBy?: Maybe<Scalars['String']>;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'Project';
    updatedAt: any;
    user: string;
    version: number;
    users: Array<string | null | undefined>;
    project: string;
    lastEditedBy: string;
    createdAt: any;
    createdBy: string;
    archive: boolean;
  };
};

export type GetProjectsQueryVariables = Exact<{
  user: Scalars['String'];
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  listProject: Array<
    | {
        __typename?: 'Project';
        version: number;
        users: Array<string | null | undefined>;
        user: string;
        updatedAt: any;
        project: string;
        lastEditedBy: string;
        createdBy: string;
        createdAt: any;
        archive: boolean;
      }
    | null
    | undefined
  >;
};

export type OnConvertSubscriptionVariables = Exact<{
  user: Scalars['String'];
  project?: Maybe<Scalars['String']>;
}>;

export type OnConvertSubscription = {
  __typename?: 'Subscription';
  onConvert?:
    | {
        __typename?: 'ConvertNotification';
        id: string;
        user: string;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        createdAt: any;
        code: number;
        models?: Array<{ __typename?: 'S3Url'; key: string; url: any }> | null | undefined;
      }
    | null
    | undefined;
};

export type OnStatsSubscriptionVariables = Exact<{
  user: Scalars['String'];
  project?: Maybe<Scalars['String']>;
}>;

export type OnStatsSubscription = {
  __typename?: 'Subscription';
  onStats?:
    | {
        __typename?: 'StatsNotification';
        id: string;
        code: number;
        createdAt: any;
        message?: string | null | undefined;
        partId?: string | null | undefined;
        project: string;
        user: string;
        type: NotificationType;
        stats?:
          | {
              __typename?: 'Stats';
              volume: number;
              minimumThickness: number;
              maximumThickness: number;
              featureFaceAreaToBackFaceAreaRatio: number;
              featureFaceArea: number;
              backFaceArea: number;
              averageThickness: number;
              area: number;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnPricingSubscriptionVariables = Exact<{
  user: Scalars['String'];
  project?: Maybe<Scalars['String']>;
}>;

export type OnPricingSubscription = {
  __typename?: 'Subscription';
  onPricing?:
    | {
        __typename?: 'PricingNotification';
        user: string;
        price?: number | null | undefined;
        project: string;
        type: NotificationType;
        message?: string | null | undefined;
        id: string;
        partId?: string | null | undefined;
        createdAt: any;
        code: number;
      }
    | null
    | undefined;
};

export type OnAnalyzeSubscriptionVariables = Exact<{
  user: Scalars['String'];
  project: Scalars['String'];
  type: AnalysisTypes;
}>;

export type OnAnalyzeSubscription = {
  __typename?: 'Subscription';
  onAnalyze?:
    | {
        __typename?: 'AnalyzeNotification';
        id: string;
        analysisType: AnalysisTypes;
        code: number;
        createdAt: any;
        user: string;
        type: NotificationType;
        project: string;
        partId?: string | null | undefined;
        message?: string | null | undefined;
        model?: { __typename?: 'S3Url'; url: any; key: string } | null | undefined;
      }
    | null
    | undefined;
};

export type CreateUserMutationVariables = Exact<{
  affiliate?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  user: Scalars['String'];
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User'; affiliate: boolean; email: string; state: string; privacy: number; eula: number; initials: string; user: string };
};

export type GetUserQueryVariables = Exact<{
  user: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'User';
    affiliate: boolean;
    createdAt: any;
    email: string;
    eula: number;
    initials: string;
    privacy: number;
    company: string;
    state: string;
    updatedAt: any;
    user: string;
  };
};

export type UpdateUserMutationVariables = Exact<{
  user: Scalars['String'];
  initials?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['Int']>;
  eula?: Maybe<Scalars['Int']>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation'; updateUser: { __typename?: 'User'; user: string; initials: string; company: string; eula: number; privacy: number } };

export const FilesListAllDocument = gql`
  query filesListAll($user: String!, $project: String!, $filter: FilesListAllFilterInput) {
    filesListAll(user: $user, project: $project, filter: $filter)
  }
`;

/**
 * __useFilesListAllQuery__
 *
 * To run a query within a React component, call `useFilesListAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesListAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesListAllQuery({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilesListAllQuery(baseOptions: Apollo.QueryHookOptions<FilesListAllQuery, FilesListAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesListAllQuery, FilesListAllQueryVariables>(FilesListAllDocument, options);
}
export function useFilesListAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesListAllQuery, FilesListAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesListAllQuery, FilesListAllQueryVariables>(FilesListAllDocument, options);
}
export type FilesListAllQueryHookResult = ReturnType<typeof useFilesListAllQuery>;
export type FilesListAllLazyQueryHookResult = ReturnType<typeof useFilesListAllLazyQuery>;
export type FilesListAllQueryResult = Apollo.QueryResult<FilesListAllQuery, FilesListAllQueryVariables>;
export function refetchFilesListAllQuery(variables?: FilesListAllQueryVariables) {
  return { query: FilesListAllDocument, variables: variables };
}
export const FilesUpdateDocument = gql`
  mutation filesUpdate($input: [filesUpdateInput!]!) {
    filesUpdate(input: $input) {
      url
      key
    }
  }
`;
export type FilesUpdateMutationFn = Apollo.MutationFunction<FilesUpdateMutation, FilesUpdateMutationVariables>;

/**
 * __useFilesUpdateMutation__
 *
 * To run a mutation, you first call `useFilesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filesUpdateMutation, { data, loading, error }] = useFilesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<FilesUpdateMutation, FilesUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FilesUpdateMutation, FilesUpdateMutationVariables>(FilesUpdateDocument, options);
}
export type FilesUpdateMutationHookResult = ReturnType<typeof useFilesUpdateMutation>;
export type FilesUpdateMutationResult = Apollo.MutationResult<FilesUpdateMutation>;
export type FilesUpdateMutationOptions = Apollo.BaseMutationOptions<FilesUpdateMutation, FilesUpdateMutationVariables>;
export const FilesGetAllDocument = gql`
  query filesGetAll($user: String!, $project: String!, $filter: FilesGetAllFilterInput) {
    filesGetAll(user: $user, project: $project, filter: $filter) {
      url
      key
    }
  }
`;

/**
 * __useFilesGetAllQuery__
 *
 * To run a query within a React component, call `useFilesGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesGetAllQuery({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilesGetAllQuery(baseOptions: Apollo.QueryHookOptions<FilesGetAllQuery, FilesGetAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesGetAllQuery, FilesGetAllQueryVariables>(FilesGetAllDocument, options);
}
export function useFilesGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesGetAllQuery, FilesGetAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesGetAllQuery, FilesGetAllQueryVariables>(FilesGetAllDocument, options);
}
export type FilesGetAllQueryHookResult = ReturnType<typeof useFilesGetAllQuery>;
export type FilesGetAllLazyQueryHookResult = ReturnType<typeof useFilesGetAllLazyQuery>;
export type FilesGetAllQueryResult = Apollo.QueryResult<FilesGetAllQuery, FilesGetAllQueryVariables>;
export function refetchFilesGetAllQuery(variables?: FilesGetAllQueryVariables) {
  return { query: FilesGetAllDocument, variables: variables };
}
export const FilesCreateDocument = gql`
  mutation filesCreate($user: String!, $project: String!, $type: FileTypes!, $id: Int, $ext: Extensions) {
    filesCreate(user: $user, project: $project, type: $type, id: $id, ext: $ext) {
      url
      key
    }
  }
`;
export type FilesCreateMutationFn = Apollo.MutationFunction<FilesCreateMutation, FilesCreateMutationVariables>;

/**
 * __useFilesCreateMutation__
 *
 * To run a mutation, you first call `useFilesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filesCreateMutation, { data, loading, error }] = useFilesCreateMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      type: // value for 'type'
 *      id: // value for 'id'
 *      ext: // value for 'ext'
 *   },
 * });
 */
export function useFilesCreateMutation(baseOptions?: Apollo.MutationHookOptions<FilesCreateMutation, FilesCreateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FilesCreateMutation, FilesCreateMutationVariables>(FilesCreateDocument, options);
}
export type FilesCreateMutationHookResult = ReturnType<typeof useFilesCreateMutation>;
export type FilesCreateMutationResult = Apollo.MutationResult<FilesCreateMutation>;
export type FilesCreateMutationOptions = Apollo.BaseMutationOptions<FilesCreateMutation, FilesCreateMutationVariables>;
export const ConvertDocument = gql`
  mutation convert($user: String!, $project: String!) {
    convert(project: $project, user: $user) {
      message
      code
    }
  }
`;
export type ConvertMutationFn = Apollo.MutationFunction<ConvertMutation, ConvertMutationVariables>;

/**
 * __useConvertMutation__
 *
 * To run a mutation, you first call `useConvertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertMutation, { data, loading, error }] = useConvertMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useConvertMutation(baseOptions?: Apollo.MutationHookOptions<ConvertMutation, ConvertMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertMutation, ConvertMutationVariables>(ConvertDocument, options);
}
export type ConvertMutationHookResult = ReturnType<typeof useConvertMutation>;
export type ConvertMutationResult = Apollo.MutationResult<ConvertMutation>;
export type ConvertMutationOptions = Apollo.BaseMutationOptions<ConvertMutation, ConvertMutationVariables>;
export const UpdateDocument = gql`
  mutation update($user: String!, $project: String!, $type: String!, $id: Int!, $exchangeId: String!, $state: Boolean!) {
    update(project: $project, user: $user, type: $type, id: $id, exchangeId: $exchangeId, state: $state) {
      status
      message
      functionName
      body
    }
  }
`;
export type UpdateMutationFn = Apollo.MutationFunction<UpdateMutation, UpdateMutationVariables>;

/**
 * __useUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMutation, { data, loading, error }] = useUpdateMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      type: // value for 'type'
 *      id: // value for 'id'
 *      exchangeId: // value for 'exchangeId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMutation, UpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMutation, UpdateMutationVariables>(UpdateDocument, options);
}
export type UpdateMutationHookResult = ReturnType<typeof useUpdateMutation>;
export type UpdateMutationResult = Apollo.MutationResult<UpdateMutation>;
export type UpdateMutationOptions = Apollo.BaseMutationOptions<UpdateMutation, UpdateMutationVariables>;
export const StatsDocument = gql`
  mutation stats($user: String!, $project: String!, $ids: [Int]) {
    stats(project: $project, user: $user, ids: $ids) {
      message
      code
      fileType
      ids
    }
  }
`;
export type StatsMutationFn = Apollo.MutationFunction<StatsMutation, StatsMutationVariables>;

/**
 * __useStatsMutation__
 *
 * To run a mutation, you first call `useStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statsMutation, { data, loading, error }] = useStatsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStatsMutation(baseOptions?: Apollo.MutationHookOptions<StatsMutation, StatsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StatsMutation, StatsMutationVariables>(StatsDocument, options);
}
export type StatsMutationHookResult = ReturnType<typeof useStatsMutation>;
export type StatsMutationResult = Apollo.MutationResult<StatsMutation>;
export type StatsMutationOptions = Apollo.BaseMutationOptions<StatsMutation, StatsMutationVariables>;
export const PricingDocument = gql`
  mutation pricing($user: String!, $project: String!, $ids: [Int]) {
    pricing(project: $project, user: $user, ids: $ids) {
      message
      code
      fileType
      ids
    }
  }
`;
export type PricingMutationFn = Apollo.MutationFunction<PricingMutation, PricingMutationVariables>;

/**
 * __usePricingMutation__
 *
 * To run a mutation, you first call `usePricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pricingMutation, { data, loading, error }] = usePricingMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePricingMutation(baseOptions?: Apollo.MutationHookOptions<PricingMutation, PricingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PricingMutation, PricingMutationVariables>(PricingDocument, options);
}
export type PricingMutationHookResult = ReturnType<typeof usePricingMutation>;
export type PricingMutationResult = Apollo.MutationResult<PricingMutation>;
export type PricingMutationOptions = Apollo.BaseMutationOptions<PricingMutation, PricingMutationVariables>;
export const AnalyzeDocument = gql`
  mutation analyze($user: String!, $project: String!, $type: AnalysisTypes!, $ids: [Int]) {
    analyze(project: $project, user: $user, type: $type, ids: $ids) {
      message
      code
      type
      fileType
      ids
    }
  }
`;
export type AnalyzeMutationFn = Apollo.MutationFunction<AnalyzeMutation, AnalyzeMutationVariables>;

/**
 * __useAnalyzeMutation__
 *
 * To run a mutation, you first call `useAnalyzeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeMutation, { data, loading, error }] = useAnalyzeMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      type: // value for 'type'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAnalyzeMutation(baseOptions?: Apollo.MutationHookOptions<AnalyzeMutation, AnalyzeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AnalyzeMutation, AnalyzeMutationVariables>(AnalyzeDocument, options);
}
export type AnalyzeMutationHookResult = ReturnType<typeof useAnalyzeMutation>;
export type AnalyzeMutationResult = Apollo.MutationResult<AnalyzeMutation>;
export type AnalyzeMutationOptions = Apollo.BaseMutationOptions<AnalyzeMutation, AnalyzeMutationVariables>;
export const AnalyzeNotificationDocument = gql`
  mutation analyzeNotification($code: Int!, $id: String!, $project: String!, $user: String!, $message: String, $type: NotificationType!, $analysisType: AnalysisTypes!) {
    analyzeNotification(code: $code, id: $id, project: $project, user: $user, message: $message, type: $type, analysisType: $analysisType) {
      user
      project
      type
      message
      id
      createdAt
      code
      analysisType
    }
  }
`;
export type AnalyzeNotificationMutationFn = Apollo.MutationFunction<AnalyzeNotificationMutation, AnalyzeNotificationMutationVariables>;

/**
 * __useAnalyzeNotificationMutation__
 *
 * To run a mutation, you first call `useAnalyzeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeNotificationMutation, { data, loading, error }] = useAnalyzeNotificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      analysisType: // value for 'analysisType'
 *   },
 * });
 */
export function useAnalyzeNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AnalyzeNotificationMutation, AnalyzeNotificationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AnalyzeNotificationMutation, AnalyzeNotificationMutationVariables>(AnalyzeNotificationDocument, options);
}
export type AnalyzeNotificationMutationHookResult = ReturnType<typeof useAnalyzeNotificationMutation>;
export type AnalyzeNotificationMutationResult = Apollo.MutationResult<AnalyzeNotificationMutation>;
export type AnalyzeNotificationMutationOptions = Apollo.BaseMutationOptions<AnalyzeNotificationMutation, AnalyzeNotificationMutationVariables>;
export const AnalyzeUpdateDocument = gql`
  mutation analyzeUpdate(
    $code: Int!
    $id: String!
    $partId: String!
    $project: String!
    $user: String!
    $model: S3UrlInput
    $message: String
    $type: NotificationType!
    $analysisType: AnalysisTypes!
  ) {
    analyzeUpdate(code: $code, id: $id, partId: $partId, project: $project, user: $user, model: $model, message: $message, type: $type, analysisType: $analysisType) {
      user
      model {
        key
        url
      }
      project
      type
      message
      id
      partId
      createdAt
      code
      analysisType
    }
  }
`;
export type AnalyzeUpdateMutationFn = Apollo.MutationFunction<AnalyzeUpdateMutation, AnalyzeUpdateMutationVariables>;

/**
 * __useAnalyzeUpdateMutation__
 *
 * To run a mutation, you first call `useAnalyzeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeUpdateMutation, { data, loading, error }] = useAnalyzeUpdateMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      partId: // value for 'partId'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      model: // value for 'model'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      analysisType: // value for 'analysisType'
 *   },
 * });
 */
export function useAnalyzeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AnalyzeUpdateMutation, AnalyzeUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AnalyzeUpdateMutation, AnalyzeUpdateMutationVariables>(AnalyzeUpdateDocument, options);
}
export type AnalyzeUpdateMutationHookResult = ReturnType<typeof useAnalyzeUpdateMutation>;
export type AnalyzeUpdateMutationResult = Apollo.MutationResult<AnalyzeUpdateMutation>;
export type AnalyzeUpdateMutationOptions = Apollo.BaseMutationOptions<AnalyzeUpdateMutation, AnalyzeUpdateMutationVariables>;
export const StatsUpdateDocument = gql`
  mutation statsUpdate($code: Int!, $id: String!, $partId: String!, $project: String!, $user: String!, $message: String, $type: NotificationType!, $stats: StatsInput!) {
    statsUpdate(code: $code, id: $id, partId: $partId, project: $project, user: $user, message: $message, type: $type, stats: $stats) {
      user
      project
      type
      message
      id
      partId
      createdAt
      code
      stats {
        volume
        area
        backFaceArea
        featureFaceArea
        averageThickness
        minimumThickness
        maximumThickness
        featureFaceAreaToBackFaceAreaRatio
      }
    }
  }
`;
export type StatsUpdateMutationFn = Apollo.MutationFunction<StatsUpdateMutation, StatsUpdateMutationVariables>;

/**
 * __useStatsUpdateMutation__
 *
 * To run a mutation, you first call `useStatsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statsUpdateMutation, { data, loading, error }] = useStatsUpdateMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      partId: // value for 'partId'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      stats: // value for 'stats'
 *   },
 * });
 */
export function useStatsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StatsUpdateMutation, StatsUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StatsUpdateMutation, StatsUpdateMutationVariables>(StatsUpdateDocument, options);
}
export type StatsUpdateMutationHookResult = ReturnType<typeof useStatsUpdateMutation>;
export type StatsUpdateMutationResult = Apollo.MutationResult<StatsUpdateMutation>;
export type StatsUpdateMutationOptions = Apollo.BaseMutationOptions<StatsUpdateMutation, StatsUpdateMutationVariables>;
export const StatsNotificationDocument = gql`
  mutation statsNotification($code: Int!, $id: String!, $project: String!, $user: String!, $message: String, $type: NotificationType!, $stats: StatsInput!) {
    statsNotification(code: $code, id: $id, project: $project, user: $user, message: $message, type: $type, stats: $stats) {
      user
      project
      type
      message
      id
      createdAt
      code
      stats {
        volume
        area
        backFaceArea
        featureFaceArea
        averageThickness
        minimumThickness
        maximumThickness
        featureFaceAreaToBackFaceAreaRatio
      }
    }
  }
`;
export type StatsNotificationMutationFn = Apollo.MutationFunction<StatsNotificationMutation, StatsNotificationMutationVariables>;

/**
 * __useStatsNotificationMutation__
 *
 * To run a mutation, you first call `useStatsNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatsNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statsNotificationMutation, { data, loading, error }] = useStatsNotificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      stats: // value for 'stats'
 *   },
 * });
 */
export function useStatsNotificationMutation(baseOptions?: Apollo.MutationHookOptions<StatsNotificationMutation, StatsNotificationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StatsNotificationMutation, StatsNotificationMutationVariables>(StatsNotificationDocument, options);
}
export type StatsNotificationMutationHookResult = ReturnType<typeof useStatsNotificationMutation>;
export type StatsNotificationMutationResult = Apollo.MutationResult<StatsNotificationMutation>;
export type StatsNotificationMutationOptions = Apollo.BaseMutationOptions<StatsNotificationMutation, StatsNotificationMutationVariables>;
export const PricingUpdateDocument = gql`
  mutation pricingUpdate($code: Int!, $id: String!, $partId: String!, $project: String!, $user: String!, $message: String, $price: Float, $type: NotificationType!) {
    pricingUpdate(code: $code, id: $id, partId: $partId, project: $project, user: $user, message: $message, price: $price, type: $type) {
      user
      project
      type
      message
      id
      partId
      createdAt
      code
      price
    }
  }
`;
export type PricingUpdateMutationFn = Apollo.MutationFunction<PricingUpdateMutation, PricingUpdateMutationVariables>;

/**
 * __usePricingUpdateMutation__
 *
 * To run a mutation, you first call `usePricingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePricingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pricingUpdateMutation, { data, loading, error }] = usePricingUpdateMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      partId: // value for 'partId'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      price: // value for 'price'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePricingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PricingUpdateMutation, PricingUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PricingUpdateMutation, PricingUpdateMutationVariables>(PricingUpdateDocument, options);
}
export type PricingUpdateMutationHookResult = ReturnType<typeof usePricingUpdateMutation>;
export type PricingUpdateMutationResult = Apollo.MutationResult<PricingUpdateMutation>;
export type PricingUpdateMutationOptions = Apollo.BaseMutationOptions<PricingUpdateMutation, PricingUpdateMutationVariables>;
export const PricingNotificationDocument = gql`
  mutation pricingNotification($code: Int!, $id: String!, $project: String!, $user: String!, $message: String, $price: Float, $type: NotificationType!) {
    pricingNotification(code: $code, id: $id, project: $project, user: $user, message: $message, price: $price, type: $type) {
      user
      project
      type
      message
      id
      createdAt
      code
      price
    }
  }
`;
export type PricingNotificationMutationFn = Apollo.MutationFunction<PricingNotificationMutation, PricingNotificationMutationVariables>;

/**
 * __usePricingNotificationMutation__
 *
 * To run a mutation, you first call `usePricingNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePricingNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pricingNotificationMutation, { data, loading, error }] = usePricingNotificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      price: // value for 'price'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePricingNotificationMutation(baseOptions?: Apollo.MutationHookOptions<PricingNotificationMutation, PricingNotificationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PricingNotificationMutation, PricingNotificationMutationVariables>(PricingNotificationDocument, options);
}
export type PricingNotificationMutationHookResult = ReturnType<typeof usePricingNotificationMutation>;
export type PricingNotificationMutationResult = Apollo.MutationResult<PricingNotificationMutation>;
export type PricingNotificationMutationOptions = Apollo.BaseMutationOptions<PricingNotificationMutation, PricingNotificationMutationVariables>;
export const ConvertNotificationDocument = gql`
  mutation convertNotification($code: Int!, $id: String!, $project: String!, $user: String!, $message: String, $models: [S3UrlInput], $type: NotificationType!) {
    convertNotification(code: $code, id: $id, project: $project, user: $user, message: $message, models: $models, type: $type) {
      user
      project
      type
      message
      id
      createdAt
      code
      models {
        key
        url
      }
    }
  }
`;
export type ConvertNotificationMutationFn = Apollo.MutationFunction<ConvertNotificationMutation, ConvertNotificationMutationVariables>;

/**
 * __useConvertNotificationMutation__
 *
 * To run a mutation, you first call `useConvertNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertNotificationMutation, { data, loading, error }] = useConvertNotificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      user: // value for 'user'
 *      message: // value for 'message'
 *      models: // value for 'models'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useConvertNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ConvertNotificationMutation, ConvertNotificationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertNotificationMutation, ConvertNotificationMutationVariables>(ConvertNotificationDocument, options);
}
export type ConvertNotificationMutationHookResult = ReturnType<typeof useConvertNotificationMutation>;
export type ConvertNotificationMutationResult = Apollo.MutationResult<ConvertNotificationMutation>;
export type ConvertNotificationMutationOptions = Apollo.BaseMutationOptions<ConvertNotificationMutation, ConvertNotificationMutationVariables>;
export const CreateProjectDocument = gql`
  mutation createProject($user: String!, $project: String!) {
    createProject(input: { project: $project, user: $user, archive: false, version: 0 }) {
      updatedAt
      user
      version
      users
      project
      lastEditedBy
      createdAt
      createdBy
      archive
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
  mutation updateProject($user: String!, $project: String!, $archive: Boolean, $version: String, $users: [String], $lastEditedBy: String) {
    updateProject(input: { project: $project, user: $user, archive: $archive, version: $version, users: $users, lastEditedBy: $lastEditedBy }) {
      updatedAt
      user
      version
      users
      project
      lastEditedBy
      createdAt
      createdBy
      archive
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      archive: // value for 'archive'
 *      version: // value for 'version'
 *      users: // value for 'users'
 *      lastEditedBy: // value for 'lastEditedBy'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const GetProjectsDocument = gql`
  query getProjects($user: String!) {
    listProject(user: $user) {
      version
      users
      user
      updatedAt
      project
      lastEditedBy
      createdBy
      createdAt
      archive
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export function refetchGetProjectsQuery(variables?: GetProjectsQueryVariables) {
  return { query: GetProjectsDocument, variables: variables };
}
export const OnConvertDocument = gql`
  subscription onConvert($user: String!, $project: String) {
    onConvert(user: $user, project: $project) {
      id
      user
      project
      type
      message
      createdAt
      code
      models {
        key
        url
      }
    }
  }
`;

/**
 * __useOnConvertSubscription__
 *
 * To run a query within a React component, call `useOnConvertSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnConvertSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnConvertSubscription({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useOnConvertSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnConvertSubscription, OnConvertSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnConvertSubscription, OnConvertSubscriptionVariables>(OnConvertDocument, options);
}
export type OnConvertSubscriptionHookResult = ReturnType<typeof useOnConvertSubscription>;
export type OnConvertSubscriptionResult = Apollo.SubscriptionResult<OnConvertSubscription>;
export const OnStatsDocument = gql`
  subscription onStats($user: String!, $project: String) {
    onStats(user: $user, project: $project) {
      id
      code
      createdAt
      message
      partId
      project
      stats {
        volume
        minimumThickness
        maximumThickness
        featureFaceAreaToBackFaceAreaRatio
        featureFaceArea
        backFaceArea
        averageThickness
        area
      }
      user
      type
    }
  }
`;

/**
 * __useOnStatsSubscription__
 *
 * To run a query within a React component, call `useOnStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStatsSubscription({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useOnStatsSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnStatsSubscription, OnStatsSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnStatsSubscription, OnStatsSubscriptionVariables>(OnStatsDocument, options);
}
export type OnStatsSubscriptionHookResult = ReturnType<typeof useOnStatsSubscription>;
export type OnStatsSubscriptionResult = Apollo.SubscriptionResult<OnStatsSubscription>;
export const OnPricingDocument = gql`
  subscription onPricing($user: String!, $project: String) {
    onPricing(user: $user, project: $project) {
      user
      price
      project
      type
      message
      id
      partId
      createdAt
      code
    }
  }
`;

/**
 * __useOnPricingSubscription__
 *
 * To run a query within a React component, call `useOnPricingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPricingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPricingSubscription({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useOnPricingSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPricingSubscription, OnPricingSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnPricingSubscription, OnPricingSubscriptionVariables>(OnPricingDocument, options);
}
export type OnPricingSubscriptionHookResult = ReturnType<typeof useOnPricingSubscription>;
export type OnPricingSubscriptionResult = Apollo.SubscriptionResult<OnPricingSubscription>;
export const OnAnalyzeDocument = gql`
  subscription onAnalyze($user: String!, $project: String!, $type: AnalysisTypes!) {
    onAnalyze(user: $user, project: $project, analysisType: $type) {
      id
      analysisType
      code
      createdAt
      user
      type
      project
      partId
      model {
        url
        key
      }
      message
    }
  }
`;

/**
 * __useOnAnalyzeSubscription__
 *
 * To run a query within a React component, call `useOnAnalyzeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnalyzeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnalyzeSubscription({
 *   variables: {
 *      user: // value for 'user'
 *      project: // value for 'project'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useOnAnalyzeSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnAnalyzeSubscription, OnAnalyzeSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnAnalyzeSubscription, OnAnalyzeSubscriptionVariables>(OnAnalyzeDocument, options);
}
export type OnAnalyzeSubscriptionHookResult = ReturnType<typeof useOnAnalyzeSubscription>;
export type OnAnalyzeSubscriptionResult = Apollo.SubscriptionResult<OnAnalyzeSubscription>;
export const CreateUserDocument = gql`
  mutation createUser($affiliate: Boolean, $email: String!, $initials: String, $user: String!) {
    createUser(input: { affiliate: $affiliate, email: $email, state: "new", privacy: 0, eula: 0, initials: $initials, user: $user }) {
      affiliate
      email
      state
      privacy
      eula
      initials
      user
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      affiliate: // value for 'affiliate'
 *      email: // value for 'email'
 *      initials: // value for 'initials'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const GetUserDocument = gql`
  query getUser($user: String!) {
    getUser(user: $user) {
      affiliate
      createdAt
      email
      eula
      initials
      privacy
      company
      state
      updatedAt
      user
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
  return { query: GetUserDocument, variables: variables };
}
export const UpdateUserDocument = gql`
  mutation updateUser($user: String!, $initials: String, $company: String, $privacy: Int, $eula: Int) {
    updateUser(input: { user: $user, initials: $initials, company: $company, privacy: $privacy, eula: $eula }) {
      user
      initials
      company
      eula
      privacy
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      initials: // value for 'initials'
 *      company: // value for 'company'
 *      privacy: // value for 'privacy'
 *      eula: // value for 'eula'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
