import React, {useState} from "react";
import LoginInput from "../../components/atoms/LoginInput";
import Copywrite from "../../components/atoms/Copywrite";
import {useFormFields} from "../../helpers/hooks";
import {useNavigate} from "react-router-dom";
import SvgButton from "../../components/atoms/SvgButton";
import BranchIcon from "../../components/assets/BranchIcon";
import Auth from "@aws-amplify/auth";
import { getCurrentUser } from "../../helpers/useUser";

interface Fields {
    code: string;
    username: string;
    oldPassword: string;
    newPassword: string;
}

export default function ResetPassword() {
    const history = useNavigate();
    const year = new Date().getFullYear();

    const [fields, handleFieldChange] = useFormFields<Fields>({
        code: "",
        username: "",
        oldPassword: "",
        newPassword: ""
    });

    const [confirmed, setConfirmed] = useState(false);
    const [failedAuth, setFailedAuth] = useState("ready to reset?");


    function validateResetForm() {
        if (fields.username.length < 3) {
            setFailedAuth("did you forget your username?");
            return false;
        } else if (fields.oldPassword.length < 8) {
            setFailedAuth("did you forget your password?");
            return false;
        } else if (fields.oldPassword.length < 8) {
            setFailedAuth("did you forget your password?");
            return false;
        }
        setFailedAuth("ready to reset?");
        return true;
    }

    function handleResetClick() {
        const changePassword = (username: string , oldPassword: string, newPassword: string) => {
            return Auth.signIn(username, oldPassword)
              .then((user) => {
                  return Auth.completeNewPassword(
                    user,               // the Cognito User Object
                    newPassword);
              })
            }
        if (validateResetForm()) {
            return changePassword(fields.username, fields.oldPassword, fields.newPassword).then((r => {
                setConfirmed(true);
                setFailedAuth("reset password!");
            })).catch((error: Error) => {
                setFailedAuth(error.message);
            });
        }
    }

    function getResetForm() {
        return (<form className="px-12 mb-4 text-highPriority font-maven-pro">
            <div className="mb-6">
                <LoginInput placeHolder={"USER NAME"} labelName={"username"} value={fields.username}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick()}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <LoginInput placeHolder={"oldPassword"} labelName={"oldPassword"} type={fields.oldPassword === "OLD PASSWORD" ? "text" : "password"}
                            value={fields.oldPassword}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick()}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <LoginInput labelName={"newPassword"} placeHolder={"NEW PASSWORD"}
                            type={fields.newPassword === "NEW PASSWORD" ? "text" : "password"}
                            value={fields.newPassword}
                            handleInputChange={handleFieldChange}
                            handleEnterPressed={() => handleResetClick()}
                            className={"px-2 py-1"}/>
            </div>
            <div className="mb-6">
                <button
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleResetClick();
                        }
                    }}
                    className="text-menuBackground w-full bg-highlight py-2 px-4 focus:outline-none focus:shadow-outline"
                    type="button" onClick={()=>handleResetClick()}>
                    RESET
                </button>
            </div>
            <p className="text-center text-highlight text-lg py-2">
                {failedAuth}
            </p>
        </form>);
    }

    function getSuccess() {
        setTimeout(async () => {
            if(await getCurrentUser()){
                history("/client/projects");
            }
        }, 1000)
        return (
            <form>
                <p className="text-center text-highlight text-lg py-2">
                    {failedAuth}
                </p>
            </form>
        );
    }

    return (
        <div className="flex h-screen bg-menuBackground">
            <div
                className="flex m-auto max-w-xs max-h-xs items-center place-content-center bg-menuBackground overflow-hidden">
                <div className="max-w-md w-full space-y-8">
                    <div className="w-full flex justify-center">
                        <SvgButton css="w-24 h-24"SvgSource={BranchIcon} />
                    </div>
                    {!confirmed
                            ? getResetForm()
                            : getSuccess()}
                    <Copywrite year={year} />
                </div>
            </div>
        </div>

    );
}
